import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { Stack, Typography } from "@mui/material";
import { CopyToClipboard } from "@src/components";
import { Code } from "@src/pages/referral-program/apis/types";
import { REFERRAL_LEVELS, REFERRAL_LINK_BASE } from "@src/pages/referral-program";

type ReferralItemProps = {
  code: Code;
  onShareClick: (code: Code) => void;
};
export const ReferralItem = ({ code, onShareClick }: ReferralItemProps) => {
  const referralRate = REFERRAL_LEVELS.find((refLevel) => refLevel.discount === code.rebate);

  return (
    <TableRow
      sx={{
        background: "#192022",
        paddingLeft: 2,
        paddingRight: 2,
        "&>td": { border: 0, fontWeight: 500, fontSize: 14, marginBottom: 1, color: "#B0BCC2", padding: 1 },
        "&>td:last-child": { paddingRight: 2, borderTopRightRadius: 4, borderBottomRightRadius: 4 },
        "&>td:first-child": { paddingLeft: 2, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 },
      }}
    >
      <TableCell scope="row">{code.code}</TableCell>
      <TableCell align="left">{referralRate ? referralRate?.discount * 100 + "%" : "-"}</TableCell>
      <TableCell align="left">
        {(1 - code.rebate) * 100}% | {code.rebate * 100}%
      </TableCell>
      <TableCell align="right">
        <Typography sx={{ color: (theme) => theme.palette.text.secondary }}>{code.referee_count || "-"}</Typography>
      </TableCell>
      <TableCell align="right">
        <Stack justifyContent="flex-end" direction="row" gap={1}>
          <ShareOutlinedIcon
            onClick={() => onShareClick(code)}
            sx={{ color: (theme) => theme.palette.text.secondary, cursor: "pointer", fontSize: 16 }}
          />
          <CopyToClipboard size={16} textToCopy={REFERRAL_LINK_BASE + code.code} />
        </Stack>
      </TableCell>
    </TableRow>
  );
};
