import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

export const Linked = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (!type) {
      navigate(`/settings/linked/exchanges`, { replace: true });
    }
  }, [location.pathname]);

  return <Outlet />;
};
