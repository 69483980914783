import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import algoliasearch from "algoliasearch";

const appId = import.meta.env.VITE_APP_ALGOLIA_APPLICATION_ID;
const apiKey = import.meta.env.VITE_APP_ALGOLIA_SEARCH_ONLY_API_KEY;

if (!appId || !apiKey)
  throw new Error(
    `env variables VITE_APP_ALGOLIA_APPLICATION_ID or VITE_APP_ALGOLIA_SEARCH_ONLY_API_KEY are not specified`
  );

export const algoliaSearchClient = algoliasearch(appId, apiKey);

export const algoliaApi = createApi({
  reducerPath: "algoliaApi",
  baseQuery: fetchBaseQuery(),
  tagTypes: ["GET_OBJECTS", "SEARCH_TOKEN", "SEARCH_TOKEN_BY_ADDRESS_AND_CHAIN"],
  keepUnusedDataFor: 9999,
  endpoints: () => ({}),
});
