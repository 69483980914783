import { useEffect, useState } from "react";
import { MenuItem, Stack, styled, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { changeUserTeamAction } from "@src/store/actions/user.action";
import { useAppDispatch } from "@src/store/hooks";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const TeamSelect = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);
  const getPossibleTeamsQuery = anbotoApi.useGetPossibleTeamsQuery(null);
  const getAccountsQuery = anbotoApi.useGetUserAccountsQuery(null);

  const accountsData = getAccountsQuery?.data?.results || [];
  const possibleTeams = getPossibleTeamsQuery?.data?.results[0]?.possible_teams?.map((team) => {
    const teamData = accountsData.find((result) => result.uuid === team.uuid);
    if (teamData && teamData.is_individual) {
      return { ...team, name: teamData.admin_email };
    }
    return team;
  });

  const [setCurrentTeam, setCurrentTeamMutation] = anbotoApi.useSetCurrentTeamMutation();

  const [currentTeamId, setCurrentTeamId] = useState<null | string>(null);

  useEffect(() => {
    if (getUserDetailsQuery.data && getPossibleTeamsQuery.data) {
      setCurrentTeamId(getUserDetailsQuery.data.team_uuid);
    }
  }, [getUserDetailsQuery, getPossibleTeamsQuery]);

  const onTeamChange = async (teamId: string) => {
    try {
      await setCurrentTeam({ teamId, userId: getUserDetailsQuery.data!.user_uuid }).unwrap();
      const teamName = getPossibleTeamsQuery.data!.results[0].possible_teams.find((x) => x.uuid === teamId)!.name;
      dispatch(changeUserTeamAction({ team_name: teamName, team_uuid: teamId }));
      window.location.reload();
    } catch {
      snackbar.enqueueSnackbar("Failed to switch team", { variant: "error" });
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const onManageAccountsClick = () => {
    setIsOpen(false);
    navigate("/settings/account");
  };

  return getUserDetailsQuery.isFetching || getPossibleTeamsQuery.isFetching ? (
    <LoadingButton loading variant="outlined" fullWidth sx={{ height: 32 }}>
      &nbsp;
    </LoadingButton>
  ) : getUserDetailsQuery.data &&
    getPossibleTeamsQuery.data &&
    currentTeamId &&
    getPossibleTeamsQuery.data.results[0].possible_teams.length > 1 ? (
    <Root
      select
      fullWidth
      size="small"
      hiddenLabel
      value={currentTeamId}
      onChange={(e) => onTeamChange(e.target.value)}
      disabled={setCurrentTeamMutation.isLoading}
      onClick={() => setIsOpen(!isOpen)}
      SelectProps={{ open: isOpen }}
    >
      {possibleTeams?.map((x) => (
        <MenuItem key={x.uuid} value={x.uuid}>
          <Stack direction="row" justifyContent="space-between" width="100%" gap={1.5}>
            <Typography
              fontSize={14}
              fontWeight={x.uuid === currentTeamId ? 600 : 400}
              sx={{
                "&::first-letter": {
                  textTransform: "capitalize",
                },
              }}
            >
              {x.name}
            </Typography>
            {x.uuid === currentTeamId && <CheckCircleIcon sx={{ color: "#2C72E3", width: 20, height: 20 }} />}
          </Stack>
        </MenuItem>
      ))}
      <MenuItem
        onClick={() => {
          onManageAccountsClick();
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%" gap={1.5}>
          <Typography fontSize={14} fontWeight={400}>
            Manage account
          </Typography>

          <ArrowForwardIcon sx={{ width: 20, height: 20 }} />
        </Stack>
      </MenuItem>
    </Root>
  ) : null;
};

const Root = styled(AnbotoTextField)(({ theme }) => ({
  width: 165,
  "& .MuiTypography-root": {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& fieldset": {
    border: "none",
  },
  "& .MuiSelect-select": {
    position: "relative",
    paddingRight: "40px !important",
    background: "#192022",
    border: "none",
    fontWeight: "600",
    fontSize: 14,
  },
  "& .MuiSelect-select:after": {
    content: "''",
    display: "block",
    borderRadius: theme.shape.borderRadius,
    position: "absolute",
    right: 8,
    top: "50%",
    bottom: 0,
    width: 20,
    height: 20,
    transform: "translateY(-50%)",
    zIndex: 0,
  },
  "& .MuiSelect-select .MuiSvgIcon-root": {
    display: "none",
  },
}));
