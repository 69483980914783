import { Stack, StackProps, Typography, styled } from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

// AnbotoConnectWalletButton needs to be used/imported in a parent component wraped by the rainbow kit provider
// otherwise account, chain, openChainModal, openConnectModal, mounted will be undefined

export const AnbotoConnectWalletButton = (props: StackProps) => (
  <ConnectButton.Custom>
    {({ account, chain, openChainModal, openConnectModal, mounted }) => {
      const ready = mounted;
      const connected = ready && account && chain;
      return (
        <Stack
          {...(!ready && {
            "aria-hidden": true,
            style: {
              opacity: 0,
              pointerEvents: "none",
              userSelect: "none",
            },
          })}
          alignItems="center"
          justifyContent="center"
          {...props}
        >
          {(() => {
            if (!connected) {
              return (
                <WalletConnectButton onClick={openConnectModal} type="button">
                  Connect Wallet
                </WalletConnectButton>
              );
            }
            if (chain.unsupported) {
              return (
                <AnbotoButton onClick={openChainModal} type="button" variant="contained">
                  <Typography noWrap>Switch to supported chain</Typography>
                </AnbotoButton>
              );
            }
          })()}
        </Stack>
      );
    }}
  </ConnectButton.Custom>
);

const WalletConnectButton = styled(AnbotoButton)<{ error?: boolean }>(({ error, theme }) => ({
  width: 200,
  background: error
    ? `linear-gradient(0deg, rgba(3, 19, 25, 0.24), rgba(3, 19, 25, 0.24)), ${theme.palette.error.main}`
    : `linear-gradient(114.93deg, ${theme.palette.primary.main} 0%, #56C3F2 100%)`,
}));
