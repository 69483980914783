import { Modal, Stack, Typography } from "@mui/material";
import { AnbotoLoadingIndicator } from "../anboto-loading-indicator/anboto-loading-indicator";

export const LoadingTgModal = () => {
  return (
    <Modal open={true} disableRestoreFocus={true} disableAutoFocus={true} disableEscapeKeyDown={true}>
      <Stack
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 505,
          height: 250,
          backgroundColor: "#232C2F",
          borderRadius: 2,
          alignItems: "center",
          justifyContent: "center",
          gap: 3,
        }}
      >
        <AnbotoLoadingIndicator />
        <Typography variant="body2" sx={{ textAlign: "center", fontSize: 16 }}>
          Please wait a moment <br /> while we create your Telegram Support group
        </Typography>
      </Stack>
    </Modal>
  );
};
