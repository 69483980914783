import { Navigate } from "react-router-dom";
import { useAppSelector } from "@src/store/hooks";

export const useInitialPath = () => {
  return useAppSelector((state) => state.uiSettings.initialPage);
};

export const InitialRedirect = () => {
  const path = useInitialPath();

  return <Navigate to={path} replace />;
};
