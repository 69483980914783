import { Box, Modal, Stack, styled, Typography } from "@mui/material";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import TeamIcon from "@src/assets/svg/team-icon.svg?react";

type CreateAccountAlertProps = {
  onOk: () => void;
};

export const CreateAccountAlert = ({ onOk }: CreateAccountAlertProps) => {
  return (
    <Modal open={true} disableAutoFocus={true} disableEnforceFocus={true}>
      <Content>
        <Stack alignItems="center">
          <TeamIcon />
        </Stack>
        <Typography sx={{ mt: 2, textAlign: "center" }}>Please create an account first</Typography>
        <Stack alignItems="center" mt={4} paddingX={15}>
          <AnbotoButton sx={{ marginX: 10, width: 200 }} variant="contained" fullWidth onClick={onOk}>
            Ok
          </AnbotoButton>
        </Stack>
      </Content>
    </Modal>
  );
};

const Content = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  border: "0px solid",
  borderColor: "#232C2F",
  backgroundColor: "#232C2F",
  borderRadius: 8,
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));
