import useMetaTags from "react-metatags-hook";
import { Outlet } from "react-router-dom";
import { Paper, Stack } from "@mui/material";
import { MenuPart } from "@src/components/sidebar-menu/sidebar-menu";
import { LAYOUT_GAP } from "@src/constants/common";
import { MenuItemId } from "@src/components/layout/main-layout/main-menu-dropdown/constants";

export enum TABS {
  PASSWORD,
  PREFERENCES,
  TWOFA,
  EXCHANGE,
  AUTH,
  WALLET,
}

export const getSideMenuItems = ({ linkPreffix = "" }) => {
  const preffix = linkPreffix ? `${linkPreffix}/` : "";

  const menu: MenuPart[] = [
    {
      header: "Profile",
      items: [
        {
          id: MenuItemId.Profile,
          url: `${preffix}profile`,
          label: "My profile",
        },
        {
          id: MenuItemId.Account,
          url: `${preffix}account`,
          label: "Account",
        },
      ],
    },
    {
      header: "Security",
      items: [
        {
          id: MenuItemId.Password,
          url: `${preffix}password`,
          label: "Change password",
        },
        {
          id: MenuItemId.TwoFa,
          url: `${preffix}two-fa`,
          label: "2FA",
        },
      ],
    },
  ];

  return menu;
};

const SettingsPage = () => {
  useMetaTags({ title: "Anboto - Settings", description: "Anboto Settings Page" });

  return (
    <Stack flexDirection="row" gap={LAYOUT_GAP} flexGrow={1}>
      <Paper sx={{ flex: 1, position: "relative", flexGrow: 1 }}>
        <Outlet />
      </Paper>
    </Stack>
  );
};

export default SettingsPage;
