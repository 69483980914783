import { Stack, StackProps } from "@mui/material";
import { AnbotoLoadingIndicator } from "../anboto-loading-indicator";

export const AnbotoContentLoader = (props: StackProps) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      position="fixed"
      zIndex={999999}
      left={0}
      bottom={0}
      sx={{ background: (theme) => theme.palette.background.default }}
      {...props}
    >
      <AnbotoLoadingIndicator style={{ height: 90 }} />
    </Stack>
  );
};
