import { Typography, Stack } from "@mui/material";
import { CloudOff } from "@mui/icons-material";
import { AnbotoButton } from "../ui/AnbotoButton/AnbotoButton";

export const TableDataError = ({ onRefresh }: { onRefresh: () => void }) => (
  <Stack alignItems="center">
    <CloudOff sx={{ color: "#4A5053" }} />
    <Typography variant="body2" sx={{ color: "#8A9296", mt: 1.5, fontSize: 12 }}>
      Oops ~ something went wrong
    </Typography>
    <AnbotoButton
      size="small"
      variant="dim"
      onClick={() => onRefresh && onRefresh()}
      sx={{ width: 160, mt: 1, background: "#232C2F" }}
    >
      Refresh
    </AnbotoButton>
  </Stack>
);
