import { useState } from "react";
import { InputLabel, Typography, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthCard } from "../layout/auth-card";
import { FormError } from "../layout/form-error";
import { FormRow } from "../layout/form-row";
import { BackButton } from "../layout/back";
import successUrl from "@src/pages/auth/assets/auth-success.svg";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { useAuth } from "@src/features/auth/hooks/use-auth";

const isValidEmail = (email: string) =>
  /* eslint-disable-next-line */
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

enum Steps {
  resetPassword = "RESET_PASSWORD",
  successMessage = "SUCCESS_MESSAGE",
}

export const ForgotPassword = () => {
  const { resetPassword } = useAuth();
  const [state, setState] = useState<Steps>(Steps.resetPassword);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty },
    setError,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: email || "",
    },
  });

  const confirmationCodeForm = useForm({
    mode: "onChange",
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = async ({ email }) => {
    setLoading(true);
    try {
      await resetPassword({ username: email });

      setState(Steps.successMessage);
    } catch (err) {
      setError("email", err.message);
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailValidation = (email: string) => {
    const isValid = isValidEmail(email);
    return isValid;
  };

  const onCodeSubmit = ({ code }) => {
    navigate(`/reset-password?email=${getValues("email")}&code=${code}`);
  };

  return (
    <AuthCard logo>
      {state === Steps.resetPassword ? (
        <>
          <Typography variant="h6" mb={3} textAlign="center">
            Reset password
          </Typography>
          <FormRow>
            <InputLabel>
              {email ? "Password reset required for the user" : "Enter your email to reset your password"}
            </InputLabel>
            <AnbotoTextField
              size="large"
              error={isDirty && !isValid}
              placeholder="Enter your email"
              type="text"
              {...register("email", { required: true, validate: handleEmailValidation })}
              name="email"
            />
            {isDirty && !isValid && <FormError>Incorrect format</FormError>}
          </FormRow>

          <AnbotoButton
            size="large"
            variant="contained"
            fullWidth={true}
            loading={loading}
            onClick={handleSubmit(onSubmit)}
            loadingIndicator="Loading..."
            sx={{ mt: 3 }}
          >
            Submit
          </AnbotoButton>

          <BackButton title="Back to login" to="/login" />
        </>
      ) : state === Steps.successMessage ? (
        <>
          <Typography variant="h6" textAlign="center">
            Check your email inbox
          </Typography>
          <Box mt={2.75} textAlign="center">
            <img src={successUrl} />
            <Typography variant="body2" fontSize={16} fontWeight="bold" mt={2}>
              We just sent you a confirmation code
              <br />
              to reset your password
              <FormRow>
                <AnbotoTextField
                  data-sentry-mask="true"
                  size="large"
                  error={Boolean(confirmationCodeForm.formState.errors.code)}
                  placeholder="Enter confirmation code"
                  {...confirmationCodeForm.register("code", {
                    required: true,
                    minLength: {
                      value: 6,
                      message: "Password must have at least 8 characters",
                    },
                  })}
                  name="code"
                />
              </FormRow>
            </Typography>
            <AnbotoButton
              onClick={confirmationCodeForm.handleSubmit(onCodeSubmit)}
              variant="contained"
              size="large"
              fullWidth
              sx={{ mt: 3 }}
            >
              Next
            </AnbotoButton>
            <Typography variant="body2" sx={{ color: (theme) => theme.custom.text.dim }} mt={2}>
              If you don’t receive an email, please check your spam folder
            </Typography>
          </Box>
        </>
      ) : null}
    </AuthCard>
  );
};
