import { Stack, Paper, IconButton, styled, Box, useTheme, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { styledOptions } from "@src/utils/styled-options";
import { MenuPart, SidebarMenu, ExtraProps } from "@src/components/sidebar-menu/sidebar-menu";
import { useAppDispatch } from "@src/store/hooks";
import { toggleSidebar } from "@src/store/slices/uiSettingsSlice";
import { RootState } from "@src/store/types";

type SidebarProps = {
  menu: MenuPart[];
  bottomMenu?: MenuPart[];
  disableExpand?: boolean;
};

export const Sidebar = ({ menu, disableExpand, bottomMenu }: SidebarProps) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const xlDown = useMediaQuery(theme.breakpoints.down("xl"));

  const { expandedSidebar, layoutView } = useSelector((state: RootState) => state.uiSettings);
  const direction = layoutView === "standard" ? "vertical" : "horizontal";
  const isVertical = direction === "vertical";
  const compact = !expandedSidebar && isVertical;

  const sidebarWidth = expandedSidebar && !disableExpand ? (xlDown ? "220px" : "250px") : "48px";

  const onToggleSidebar = () => {
    dispatch(toggleSidebar());
  };

  return (
    <Paper>
      <Stack
        data-testid="sidebar"
        sx={{
          minWidth: sidebarWidth,
          height: "calc(100vh - 24px - 48px)",
          flexDirection: "column",
          alignItems: "space-between",
          position: "sticky",
          top: 4,
        }}
      >
        <SidebarMenu
          menu={menu}
          toggleSidebar={onToggleSidebar}
          compact={disableExpand || compact}
          isVertical={isVertical}
        />
        {bottomMenu && (
          <div style={{ position: "sticky", bottom: 30 }}>
            <SidebarMenu
              menu={bottomMenu}
              toggleSidebar={onToggleSidebar}
              compact={disableExpand || compact}
              isVertical={isVertical}
            />
          </div>
        )}
      </Stack>
    </Paper>
  );
};

export const ToggleSidebarButton = styled(IconButton)({
  "& .MuiSvgIcon-root": {
    color: "#3B4043",
  },
  "&:hover .MuiSvgIcon-root": {
    color: "#8A9296",
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Root = styled(
  Box,
  styledOptions
)<ExtraProps>(({ vertical }) => ({
  display: "flex",
  flexDirection: vertical ? "column" : "row",
  alignItems: vertical ? "stretch" : "center",
}));
