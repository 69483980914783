import { SearchIndex } from "algoliasearch";
import BigNumber from "bignumber.js";
import {
  AlgoliaSearchCefiResult,
  CefiAllocationWithIcon,
  DefiAllocationWithIcon,
  Wallet,
} from "@src/store/apis/anbotoApi/types";
import {
  DEFAULT_COIN,
  DEFAULT_STABLE_COIN,
  MAIN_ACCOUNT_VALUE,
  PAIR_SYMBOL_DELIMITER,
} from "@src/pages/portfolio/constant";
import { STABLE_COINS_LIST } from "@src/assets/tokenlists/stablecoins/stablecoins";
import { CEFI_EXCHANGE_NAME } from "@src/constants/common";
import { formatWithDelimiter } from "@src/utils/format";

export const numberToPercent = (number: number) => {
  return number.toLocaleString(undefined, { style: "percent", minimumFractionDigits: 2 });
};

export const getWalletName = (wallets: Wallet[], address: string): string => {
  const wallet = wallets.filter((m) => {
    return m.address.toUpperCase() === address.toUpperCase();
  });
  return wallet[0] ? wallet[0].name : "";
};

export const formatNumber = (number: any, decimals: number) => {
  return Number(number).toFixed(decimals);
};

export const capitalizeFirstLetter = (item: string) => {
  return item.charAt(0).toUpperCase() + item.slice(1);
};

export const getCountChartsInSlide = (componentWidth: number): number => {
  // 440 width of chart, 80 width of gap between charts - size of other elements in the page
  return componentWidth > 1550 ? Math.floor(componentWidth / (442 + 80)) : 2;
};

export const getOffsetTop = (refCurrent) => {
  return refCurrent ? refCurrent["offsetTop"] + getOffsetTop(refCurrent["offsetParent"]) : 0;
};

export const getOffsetLeft = (refCurrent) => {
  return refCurrent ? refCurrent["offsetLeft"] + getOffsetLeft(refCurrent["offsetParent"]) : 0;
};

export const getKey = (obj: DefiAllocationWithIcon): string => {
  return obj.token_symbol + obj.chain_name;
};

export const isStableCoin = (asset: string): boolean => {
  return !!STABLE_COINS_LIST[asset];
};

export const getPair = (asset: string, isPosition: boolean): string => {
  return isPosition
    ? asset
    : isStableCoin(asset)
      ? DEFAULT_COIN + PAIR_SYMBOL_DELIMITER + asset
      : asset + PAIR_SYMBOL_DELIMITER + DEFAULT_STABLE_COIN;
};

export const buildTradeLinks = (
  searchResult: AlgoliaSearchCefiResult[],
  assets: CefiAllocationWithIcon[]
): Record<string, string | undefined> | undefined => {
  return searchResult.reduce<Record<string, string | undefined>>((acc, x, i) => {
    acc[assets[i].token_symbol] = x
      ? `/trades/cefi?symbol=${encodeURIComponent(x.objectID)}&account=${assets[i].subaccount || MAIN_ACCOUNT_VALUE}`
      : undefined;
    return acc;
  }, {});
};

export const proposeNotFoundObjects = async (currentSearchResult, assets, index: SearchIndex) => {
  if (!currentSearchResult.includes(null)) return currentSearchResult;

  const nullIndexes = currentSearchResult.reduce((acc: number[], x, i) => (x === null ? [...acc, i] : acc), []);

  await Promise.allSettled(
    nullIndexes.map(async (i) => {
      const data = await index.search<AlgoliaSearchCefiResult>(assets[i].token_symbol, {
        filters: `market_type:${assets[i].market_type} AND exchange:${CEFI_EXCHANGE_NAME[assets[i].exchange_id]}`,
      });

      if (data.hits.length) {
        currentSearchResult[i] = data.hits[0];
      } else {
        currentSearchResult[i] = undefined;
      }
    })
  );

  return currentSearchResult;
};

export const roundAmount = (amount: string, significantFigures = 2): string => {
  const bnAmount = new BigNumber(amount);

  if (bnAmount.isInteger()) return bnAmount.toString();

  if (bnAmount.isGreaterThan(-1) && bnAmount.isLessThan(1)) {
    return bnAmount.toPrecision(significantFigures);
  } else {
    return bnAmount.toFixed(2);
  }
};

export const formatAmount = (amount: string) => {
  const absAmount = new BigNumber(amount).abs();
  const [, r] = absAmount.toString().split(".");

  let decimalPlaces = r ? r.length : 0;

  if (absAmount.isGreaterThanOrEqualTo(1) && !absAmount.isInteger()) {
    decimalPlaces = 2;
  }

  return formatWithDelimiter(new BigNumber(amount).toFixed(decimalPlaces));
};
