import { queryFnFactory } from "../utils";
import { ChainId, Token } from "@src/pages/defi/types";
import { algoliaApi, algoliaSearchClient } from "@src/store/apis/algoliaApi/algolia-api";

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const tokenListsIndex = algoliaSearchClient.initIndex(import.meta.env.VITE_APP_ALGOLIA_DEFI_TOKENS_INDEX!);

export const defiTokens = algoliaApi.injectEndpoints({
  endpoints: (builder) => ({
    searchToken: builder.query<
      Token[],
      { searchString: string; chainId?: ChainId; limit?: number; attributes?: string[] }
    >({
      providesTags: ["SEARCH_TOKEN"],
      queryFn: queryFnFactory<
        { searchString: string; chainId?: ChainId; limit?: number; attributes?: string[] },
        Token[]
      >(async ({ searchString, chainId, limit = 50, attributes }) => {
        try {
          const params: { attributesToRetrieve: string[]; hitsPerPage: number; filters?: string } = {
            attributesToRetrieve: attributes || [
              "symbol",
              "name",
              "address",
              "chainId",
              "logoURI",
              "decimals",
              "trusted",
            ],
            hitsPerPage: limit,
          };

          if (chainId) params.filters = `chainId: ${chainId}`;

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const res = await tokenListsIndex.search(searchString!, params);

          return (res?.hits as unknown as Token[]) || [];
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log("searchToken error", err);
          return [];
        }
      }),
    }),
    getTokenByAddressAndChain: builder.query<Token | null, { address: string; chainId: ChainId }>({
      providesTags: ["SEARCH_TOKEN_BY_ADDRESS_AND_CHAIN"],
      queryFn: queryFnFactory<{ address: string; chainId: ChainId }, Token | null>(async ({ address, chainId }) => {
        try {
          const resObj = await tokenListsIndex.getObject(`${chainId}_${address.toLowerCase()}`);

          return (resObj as unknown as Token) || null;
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log("searchTokenByAddressAndChain error", err);

          return null;
        }
      }),
    }),
    getTokensByAddressesAndChain: builder.query<Token[], { addresses: string[]; chainId: ChainId }>({
      providesTags: ["SEARCH_TOKEN_BY_ADDRESS_AND_CHAIN"],
      queryFn: queryFnFactory<{ addresses: string[]; chainId: ChainId }, Token[]>(async ({ addresses, chainId }) => {
        try {
          const tokenIds = addresses.map((address) => `${chainId}_${address.toLowerCase()}`);

          if (!tokenIds?.length) {
            return [];
          }

          const resObj = await tokenListsIndex.getObjects(tokenIds);

          return (resObj?.results as unknown as Token[]) || [];
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log("searchTokensByAddressesAndChain error", err);

          return [];
        }
      }),
    }),
  }),
});

export const {
  useSearchTokenQuery,
  useGetTokenByAddressAndChainQuery,
  useLazyGetTokenByAddressAndChainQuery,
  useLazyGetTokensByAddressesAndChainQuery,
} = defiTokens;
