import { useNavigate } from "react-router-dom";
import { Stack, Typography, Tooltip, StackProps } from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";

export const PaginationTooltip = ({ limit, ...props }: { limit: number } & StackProps) => {
  const navigate = useNavigate();

  const onClick = () => navigate("/settings/transactions-history");

  return (
    <Stack direction="row" gap={0.5} {...props} alignItems="center">
      <Typography variant="body2" fontSize="small" color="text.disabled">
        Loaded only the latest {limit} records.
      </Typography>
      <Tooltip
        title={
          <Typography variant="body2" fontSize="small">
            For a complete history please go to{" "}
            <u style={{ cursor: "pointer" }} onClick={onClick}>
              transactions history
            </u>
            , click ”Export” to download the CSV file.
          </Typography>
        }
      >
        <InfoIcon sx={{ color: "text.disabled", fontSize: 16 }} />
      </Tooltip>
    </Stack>
  );
};
