import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { WatchlistView } from "./watchlistSlice";
import { DEFAULT_UI_VERSION } from "@src/constants/common";
import { PORTFOLIO_CURRENCIES } from "@src/pages/portfolio/constant";
import { ExchangeApiError } from "@src/store/apis/anbotoApi/types";

export interface UiSettings {
  currency: string;
  layoutView: "standard" | "vertical";
  expandedSidebar: boolean;
  watchlist: boolean;
  watchlistView: WatchlistView;
  watchlistAutoScroll: boolean;
  version: number;
  requestedTeamAccount: boolean;
  requestedMoreAccounts: number | undefined;
  promos: { popups: string[]; banners: string[] };
  feeManagerIntro: boolean;
  apiKeysErrors?: {
    [id: string]: ExchangeApiError;
  };
  widgetsEnabled: boolean;
  isLayoutLocked: boolean;
  initialPage: string;
}

const initialState: UiSettings = {
  currency: PORTFOLIO_CURRENCIES[0],
  layoutView: "standard",
  expandedSidebar: false,
  watchlist: true,
  watchlistView: WatchlistView.TOP_LIST,
  watchlistAutoScroll: true,
  version: DEFAULT_UI_VERSION,
  requestedTeamAccount: false,
  feeManagerIntro: true,
  promos: { popups: [], banners: [] },
  requestedMoreAccounts: undefined,
  widgetsEnabled: true,
  isLayoutLocked: true,
  initialPage: "/trades/cefi",
};

export const uiSettingsSlice = createSlice({
  name: "uiSettings",
  initialState,
  reducers: {
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    setWatchlistView: (state, action) => {
      state.watchlistView = action.payload;
    },
    toggleWatchlistAutoScroll: (state) => {
      state.watchlistAutoScroll = !state.watchlistAutoScroll;
    },
    toggleView: (state) => {
      state.layoutView = current(state).layoutView === "standard" ? "vertical" : "standard";
    },
    toggleSidebar: (state) => {
      state.expandedSidebar = !state.expandedSidebar;
    },
    toggleWatchlist: (state) => {
      state.watchlist = !state.watchlist;
    },
    changeUIVersion: (state, action: PayloadAction<number>) => {
      state.version = action.payload || DEFAULT_UI_VERSION;
    },
    setRequestedTeamAccount: (state) => {
      state.requestedTeamAccount = true;
    },
    setShowFeeManagerIntro: (state, action: PayloadAction<boolean>) => {
      state.feeManagerIntro = !!action.payload;
    },
    updatePromos: (state, action: PayloadAction<{ promos: { popups: string[]; banners: string[] } }>) => {
      if (action.payload.promos?.popups?.length) {
        const popups = state.promos?.popups?.filter((popup) => action.payload.promos?.popups?.includes(popup)) || [];
        state.promos = { ...(state.promos || {}), popups };
      }

      if (action.payload.promos?.banners?.length) {
        const banners =
          state.promos?.banners?.filter((banner) => action.payload.promos?.banners?.includes(banner)) || [];
        state.promos = { ...(state.promos || {}), banners };
      }
    },
    setPopupOrBannerShowed: (state, action: PayloadAction<{ type: "banner" | "popup"; id: string }>) => {
      if (action.payload.type === "banner") {
        const currentBanners = state.promos?.banners || [];
        if (!currentBanners.some((x) => x === action.payload.id)) {
          state.promos = {
            ...(state.promos || {}),
            banners: [...currentBanners, action.payload.id],
          };
        }
      } else {
        const currentPopups = state.promos?.popups || [];
        if (!currentPopups.some((x) => x === action.payload.id)) {
          state.promos = {
            ...(state.promos || {}),
            popups: [...currentPopups, action.payload.id],
          };
        }
      }
    },
    setRequestedMoreAccounts: (state, action: PayloadAction<number>) => {
      state.requestedMoreAccounts = action.payload || 0;
    },
    setExchangeApiKeyError: (
      state,
      action: PayloadAction<{
        [id: string]: ExchangeApiError;
      }>
    ) => {
      state.apiKeysErrors = { ...(state.apiKeysErrors || {}), ...action.payload };
    },
    deleteExchangeApiKeyError: (state, action: PayloadAction<string>) => {
      if (state.apiKeysErrors?.[action.payload]) state.apiKeysErrors[action.payload] = { type: "ok", text: "" };
    },
    toggleWidgets: (state) => {
      state.widgetsEnabled = !state.widgetsEnabled;
    },
    setLocked: (state, action: PayloadAction<boolean>) => {
      state.isLayoutLocked = action.payload;
    },
    setInitialPage: (state, action: PayloadAction<string>) => {
      state.initialPage = action.payload;
    },
  },
});

export const {
  setCurrency,
  setWatchlistView,
  toggleView,
  toggleSidebar,
  toggleWatchlist,
  toggleWatchlistAutoScroll,
  changeUIVersion,
  setPopupOrBannerShowed,
  setRequestedTeamAccount,
  setRequestedMoreAccounts,
  setShowFeeManagerIntro,
  updatePromos,
  setExchangeApiKeyError,
  deleteExchangeApiKeyError,
  toggleWidgets,
  setLocked,
  setInitialPage,
} = uiSettingsSlice.actions;

export default uiSettingsSlice.reducer;
