import React, { forwardRef } from "react";
import { MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import { DateTime, DurationLike, DurationUnit } from "luxon";
import { InfoOutlined } from "@mui/icons-material";
import { AnbotoTextField, AnbotoTextFieldProps } from "@src/components/ui/AnbotoTextField/AnbotoTextField";

export const expirationTimeOptions = ["1week", "1month", "6months"] as const;

export type ExpirationTime = (typeof expirationTimeOptions)[number];

export const ExpirationTimeLabel: Record<ExpirationTime, string> = {
  ["1week"]: "1 Week",
  ["1month"]: "1 Month",
  ["6months"]: "6 Months",
};

export const getExpirationTimeMs = (period: ExpirationTime, startTimeMs?: number) => {
  const time = startTimeMs ? DateTime.fromMillis(startTimeMs) : DateTime.local();

  const key = period.replace(/[0-9]/g, "");
  const length = period.replace(/[^0-9]/g, "");
  const offset = { [key]: +length } as DurationLike;

  const res = time.plus(offset).toUTC().valueOf();
  return res;
};

export const getExpirationTimeFromMs = (startMs: number, endMs: number) => {
  const durations = ["months", "weeks", "days"];

  for (let i = 0; i < durations.length; i++) {
    const duration = durations[i] as DurationUnit;
    const res = DateTime.fromMillis(endMs).diff(DateTime.fromMillis(startMs), duration).toObject();

    const amount = Math.round(res[duration]);

    if (amount >= 1) return `${amount}${amount === 1 ? duration.slice(0, -1) : duration}` as ExpirationTime;
  }
  return "" as ExpirationTime;
};

export const ExpirationTimeSelect = forwardRef((props: AnbotoTextFieldProps, ref) => (
  <AnbotoTextField
    {...props}
    inputRef={ref}
    select
    label={
      <Stack direction="row" gap={1} alignItems="center">
        <Typography fontSize={11} variant="caption" sx={{ lineHeight: 1 }}>
          {props.label || "Expiration"}
        </Typography>
        <Tooltip title="The time period the order will be active (ready to execute). The Default expiration period is 6 months. You can choose a shorter expiration period, if needed. ">
          <InfoOutlined sx={{ cursor: "pointer", fontSize: 12 }} />
        </Tooltip>
      </Stack>
    }
    size="small"
    fullWidth
  >
    {expirationTimeOptions.map((expiration) => (
      <MenuItem key={expiration} value={expiration}>
        {ExpirationTimeLabel[expiration]}
      </MenuItem>
    ))}
  </AnbotoTextField>
));

ExpirationTimeSelect.displayName = "ExpirationTimeSelect";
