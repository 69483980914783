import { SvgIconComponent } from "@mui/icons-material";
import { styled } from "@mui/material";

export const getStyledIcon = (icon: SvgIconComponent, isValid?: boolean) => {
  const Icon = styled(icon)(({ theme }) => ({
    color: !isValid ? theme.custom.text.dim : theme.custom.colors.secondary,
  }));

  return <Icon />;
};
