import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuthenticatorContext } from "@src/features/auth/hooks/use-authenticator-context";

const IsAuthenticated = ({ value = true }: { value?: boolean }) => {
  const location = useLocation();
  const { ready, authenticated } = useAuthenticatorContext();

  if (ready && !authenticated && value) return <Navigate to="/login" state={{ from: location }} />;

  if (ready && authenticated && !value) return <Navigate to="/" replace state={{ from: location }} />;

  return <Outlet />;
};

export { IsAuthenticated };
