import { useEffect } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams, useMatch } from "react-router-dom";
import { Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import { MainNavbar } from "./main-navbar";
import { MainSidebar } from "./main-sidebar";
import { LAYOUT_GAP, LAYOUT_MIN_WIDTH } from "@src/constants/common";
import { RainbowProvider } from "@src/pages/defi/rainbow-provider";
import { AssetsSubscriber } from "@src/pages/portfolio/components/assets-subscriber";
import { WatchlistTopBar } from "@src/components/watchlist/watchlist-top-bar";
import { BottomBar } from "@src/components/bottom-bar";
import { JoinAccountModal, TermsModal } from "@src/pages/auth/registration";
import { useAppSelector } from "@src/store/hooks";
import { OrderSymbol } from "@src/store/apis/anbotoApi/types";
import { useSymbolDataFromSearchParamsModal } from "@src/features/widgets-layout/hooks/use-symbol-data-from-search-params";
import { SettingsSteps } from "@src/pages/settings/settings-steps";
import { useInitiateUserData } from "@src/hooks/use-initiate-user-data";
import { useWisepops } from "@src/hooks/use-wisepops";
import { useGroupDataModal } from "@src/features/widgets-layout/hooks/use-group-data-modal";
import { useCefiOrDefiModalShow } from "@src/hooks/use-cefi-or-defi-modal-show";
import { CefiOrDefiModal } from "@src/components/cefi-or-defi-modal/cefi-or-defi-modal";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";
import { SuspenseWithLoader } from "@src/components/suspense-with-loader";

export const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const cefiMatchUrl = useMatch("/trades/cefi");
  const isCefiPage = cefiMatchUrl?.pathname === "/trades/cefi";

  const [searchParams] = useSearchParams();
  const showGroupDataModal = useGroupDataModal();
  const isTestNet = useTestnetEnabled();

  const { layoutView } = useAppSelector((state) => state.uiSettings);
  const user_uuid = useAppSelector((state) => state.user.user_uuid);
  const terms_of_use = useAppSelector((state) => state.user.terms_of_use);
  const showCefiOrDefiModal = useCefiOrDefiModalShow();

  const { accounts, ready, subscribed } = useInitiateUserData();

  useSymbolDataFromSearchParamsModal({ trigger: ready });
  useWisepops(ready);

  const isInvitedToAccount = searchParams.get("isInvitedToAccount") || false;

  const termsAgreed = !!terms_of_use;

  const showSidebar = layoutView === "standard";

  useEffect(() => {
    if (user_uuid) {
      window.analytics?.identify(user_uuid); // to track in segments analytics
      Sentry.setUser({ id: user_uuid }); // to track in sentry
      window.Beamer?.update({ user_id: user_uuid });
    }
  }, [user_uuid]);

  useEffect(() => {
    console.log("env: ", import.meta.env.VITE_APP_DEPLOY_ENV);
  }, []);

  useEffect(() => {
    if (accounts && !accounts.length) {
      navigate("/settings/account");
    }
  }, [location.pathname]);

  const handleWatchlistSymbolClick = ({ symbol, exchange, market_type }: OrderSymbol) => {
    if (isCefiPage) showGroupDataModal(symbol, exchange, market_type);
    else navigate(`/trades/cefi?symbol=${symbol}&exchange=${exchange}&market_type=${market_type}`);
  };

  return (
    <>
      {ready ? (
        <RainbowProvider>
          <AssetsSubscriber />
          <Stack id="main-layout" px={LAYOUT_GAP} pb={3} minWidth={LAYOUT_MIN_WIDTH} flex={1} minHeight="100vh">
            <MainNavbar />
            <Stack flexDirection="row" gap={LAYOUT_GAP}>
              {showSidebar && <MainSidebar />}
              <Stack flex={1} position="relative">
                <SettingsSteps sx={{ mb: LAYOUT_GAP }} />
                <SuspenseWithLoader>
                  <Outlet />
                </SuspenseWithLoader>
              </Stack>
            </Stack>
            {!termsAgreed && <TermsModal />}
            {isInvitedToAccount && <JoinAccountModal isInvitedToAccount={isInvitedToAccount} />}
            {showCefiOrDefiModal && !isInvitedToAccount && termsAgreed && !isTestNet && <CefiOrDefiModal />}
          </Stack>
        </RainbowProvider>
      ) : null}

      {subscribed && (
        <BottomBar>
          <WatchlistTopBar sx={{ maxWidth: "100%" }} onSymbolClick={handleWatchlistSymbolClick} />
        </BottomBar>
      )}
    </>
  );
};
