import { Navigate, Route } from "react-router-dom";
import { PortfolioPageLazy } from "./pages/portfolio-page.lazy";
import { AllocationsCefiFundsLazy } from "./pages/portfolio-allocations/allocations-cefi-funds.lazy";
import { AllocationsDefiFundsLazy } from "./pages/portfolio-allocations/allocations-defi-funds.lazy";
import { PortfolioCefiExchangesLazy } from "./pages/portfolio-exchanges/portfolio-cefi.lazy";
import { PortfolioDefiExchangesLazy } from "./pages/portfolio-exchanges/portfolio-defi.lazy";
import { HideInTestnet } from "@src/components/hide-in-testnet/hide-in-testnet";
import { SuspenseWithLoader } from "@src/components/suspense-with-loader";

export const portfolioRoutes = (
  <Route
    path="/portfolio"
    element={
      <HideInTestnet>
        <SuspenseWithLoader>
          <PortfolioPageLazy />
        </SuspenseWithLoader>
      </HideInTestnet>
    }
  >
    <Route path="cefi" element={<PortfolioCefiExchangesLazy key="/portfolio/cefi" />}>
      <Route path="funds" element={<AllocationsCefiFundsLazy key="/portfolio/cefi/funds" />} />
      <Route index element={<Navigate to="/portfolio/cefi/funds" replace />} />
    </Route>
    <Route path="defi" element={<PortfolioDefiExchangesLazy key="/portfolio/defi" />}>
      <Route path="funds" element={<AllocationsDefiFundsLazy key="/portfolio/defi/funds" />} />
      <Route index element={<Navigate to="/portfolio/defi/funds" replace />} />
    </Route>
    <Route index element={<Navigate to="/portfolio/cefi" replace />} />
  </Route>
);
