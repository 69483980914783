import { Stack, Typography, useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { formatUsd } from "@src/utils/format";
import { REFERRAL_LEVELS, ReferralBox } from "@src/pages/referral-program";

const TierStructure = () => {
  const theme = useTheme();
  return (
    <ReferralBox>
      <Stack direction="row" gap={3}>
        <Stack flex={1}>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontSize: 10, padding: 0.8, fontWeight: 400 }}>Level</TableCell>
                  <TableCell sx={{ fontSize: 10, padding: 0.8, fontWeight: 400 }} align="left">
                    {" "}
                    Monthly trading volume (USD)
                  </TableCell>
                  <TableCell sx={{ fontSize: 10, padding: 0.8, fontWeight: 400 }} align="right">
                    Referral rate{" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {REFERRAL_LEVELS.map((level) => (
                  <TableRow key={level.name}>
                    <TableCell sx={{ fontSize: 14, padding: 0.8, border: 0 }} scope="row">
                      {level.name}
                    </TableCell>
                    <TableCell sx={{ fontSize: 14, padding: 0.8, border: 0 }} align="left">
                      {formatUsd(level.minVolume)}
                    </TableCell>
                    <TableCell sx={{ fontSize: 14, padding: 0.8, border: 0 }} align="right">
                      {level.discount * 100}%
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>

        <Stack px={3} py={2} sx={{ background: "#031116", borderRadius: 1 }} flex={1} direction="column" gap={2}>
          <Stack direction="row" alignItems="center">
            <Stack height={32} mr={2} sx={{ borderLeft: "3px solid #2C72E3" }}></Stack>
            <Typography color={theme.palette.text.secondary} fontSize={14}>
              No commission is provided if referee executes trades on a fee-less exchange.
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Stack height={32} mr={2} sx={{ borderLeft: "3px solid #2C72E3" }}></Stack>
            <Typography color={theme.palette.text.secondary} fontSize={14}>
              Each user&apos;s referral rate for each month will be decided based on it&apos;s previous month&apos;s
              trading volume
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </ReferralBox>
  );
};

export default TierStructure;
