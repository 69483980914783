import { Stack, useTheme, MenuItem, Typography, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { CONFIGS } from "../../widgets/configs";
import { WidgetsGridView, WidgetName } from "../../types";
import { useWidgetsGridLayout } from "../../hooks/use-widgets-grid-layout";
import { ICONS, MENU_ITEMS, MENU_ITEM_LABEL } from "./constants";
import { useAppSelector } from "@src/store/hooks";

const renderIcon = (name: WidgetName) => {
  const Comp = ICONS[name];

  return <Comp width={40} />;
};

export const WidgetsList = ({ onWidgetClick }: { onWidgetClick: (name: WidgetName) => void }) => {
  const theme = useTheme();
  const { layout: cefiWidgetsLayout } = useWidgetsGridLayout(WidgetsGridView.CEFI);
  const { layout: defiWidgetsLayout } = useWidgetsGridLayout(WidgetsGridView.DEFI);
  const { layout: otcWidgetsLayout } = useWidgetsGridLayout(WidgetsGridView.OTC);
  const view = useAppSelector((state) => state.widgets.view);

  const layout =
    view === WidgetsGridView.CEFI
      ? cefiWidgetsLayout
      : view === WidgetsGridView.OTC
        ? otcWidgetsLayout
        : defiWidgetsLayout;

  const widgetsOnGrid = Object.values(layout).reduce<Record<WidgetName, number>>(
    (res, { name }) => ({ ...res, [name]: (res[name] || 0) + 1 }),
    {} as Record<WidgetName, number>
  );

  return (
    <>
      {MENU_ITEMS[view].map((name) =>
        CONFIGS[name] ? (
          <MenuItem
            key={name}
            onClick={() => onWidgetClick(name)}
            disabled={widgetsOnGrid[name] >= CONFIGS[name].options.maxNumber}
          >
            <Stack my={1} height={40} direction="row" justifyContent="space-between" flex={1}>
              <Stack alignItems="center" direction="row" gap={2}>
                {renderIcon(name)}
                <Typography variant="body2" color={theme.palette.text.primary} fontSize={14} fontWeight={700}>
                  {MENU_ITEM_LABEL[name]}
                </Typography>
              </Stack>

              <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="body2" color={theme.palette.text.secondary} fontSize={14} fontWeight={700}>
                  ({widgetsOnGrid[name] || "0"}/{CONFIGS[name].options.maxNumber})
                </Typography>
                <IconButton
                  size="small"
                  disableRipple
                  sx={{
                    width: 20,
                    height: 20,
                    color:
                      widgetsOnGrid[name] >= CONFIGS[name].options.maxNumber
                        ? theme.palette.text.secondary
                        : theme.palette.text.primary,
                  }}
                >
                  <AddCircleOutlineIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </Stack>
            </Stack>
          </MenuItem>
        ) : null
      )}
    </>
  );
};
