import React from "react";
import { Stack, InputLabel, Typography, CircularProgress, Divider, Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { AuthCard } from "../layout/auth-card";
import { FormRow } from "../layout/form-row";
import { FormLink } from "../layout/form-link";
import { FormError } from "../layout/form-error";
import GoogleAuthIcon from "@src/pages/auth/assets/auth-google.svg?react";
import { AnbotoTextField } from "@src/components/ui/AnbotoTextField/AnbotoTextField";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export const LoginForm = ({
  onSubmit,
  onGoogleButtonClick,
  loading,
  googleLoading,
  authErrors,
  defaultEmail = "",
}: {
  onSubmit: ({ email, password }: { email: string; password: string }) => void;
  onGoogleButtonClick?: () => void;
  loading: boolean;
  googleLoading?: boolean;
  authErrors: { email: string; google: string };
  defaultEmail?: string;
}) => {
  const emailInputRef = React.useRef<HTMLInputElement>(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: defaultEmail,
      password: "",
      googleAuth: "",
    },
  });

  React.useEffect(() => {
    emailInputRef.current?.focus();
  }, []);

  return (
    <AuthCard logo>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack width="100%" gap={1}>
          <FormRow>
            <InputLabel>Email</InputLabel>
            <AnbotoTextField
              data-test-id="email"
              size="large"
              inputProps={{ tabIndex: 1 }}
              error={Boolean(errors.email)}
              placeholder="Enter valid email address"
              type={"email"}
              inputRef={emailInputRef}
              {...register("email", {
                required: "The email must be filled out",
                validate: (email) => {
                  if (email.length < 0) return "The email must be filled out";
                  return true;
                },
              })}
              name="email"
            />
            {errors.email && <FormError>{errors.email.message}</FormError>}
            {authErrors.email && <FormError>{authErrors.email}</FormError>}
          </FormRow>

          <FormRow>
            <Stack direction="row" justifyContent="space-between">
              <InputLabel>Password</InputLabel>
              <FormLink to="/forgot-password" tabIndex={4}>
                Forgot Password
              </FormLink>
            </Stack>
            <AnbotoTextField
              data-sentry-mask="true"
              data-test-id="password"
              size="large"
              error={Boolean(errors.password)}
              placeholder="Min 10 characters"
              type="password"
              inputProps={{ tabIndex: 1 }}
              {...register("password", {
                required: true,
                validate: (password) => {
                  if (password.length < 0) return "The password must be filled out";
                  return true;
                },
              })}
              name="password"
            />
            {errors.password && <FormError>The password must be filled out</FormError>}
          </FormRow>
          <Box>
            <AnbotoButton
              data-test-id="submit-button"
              tabIndex={1}
              sx={{ marginTop: 2, "&:focus": { outline: 1 } }}
              size="large"
              variant="contained"
              fullWidth={true}
              loading={loading}
              loadingIndicator={<CircularProgress color="inherit" size={16} />}
              type="submit"
            >
              Login
            </AnbotoButton>

            {onGoogleButtonClick && (
              <>
                <Divider sx={{ marginTop: 2, marginBottom: 2 }}>
                  <Typography variant="body2" color="text.secondary">
                    Or login with
                  </Typography>
                </Divider>
                <AnbotoButton
                  size="large"
                  tabIndex={1}
                  sx={{
                    justifyContent: "space-between",
                    textTransform: "none",
                    borderColor: (theme) => theme.palette.background.default,
                  }}
                  endIcon={<img src={GoogleAuthIcon} />}
                  variant="outlined"
                  fullWidth={true}
                  loading={googleLoading}
                  onClick={onGoogleButtonClick}
                  loadingIndicator={<CircularProgress color="inherit" size={16} />}
                >
                  Gmail
                </AnbotoButton>

                <Typography variant="body2" color="text.secondary" textAlign="center" mt={1}>
                  In order to use Gmail, you must first link your Anboto account
                </Typography>
              </>
            )}

            <Divider sx={{ marginTop: 3, marginBottom: 3 }} />

            <Typography variant="body2" color="text.secondary" fontWeight="600" textAlign="center">
              No Account yet?
            </Typography>

            <Stack direction="row" gap={0.5} justifyContent="center">
              <FormLink to="/referral-code-sign-up" tabIndex={4}>
                Sign Up
              </FormLink>
              <Typography variant="body2" color="text.secondary" textAlign="center">
                with referral code
              </Typography>
              {errors.googleAuth && <FormError>{errors.googleAuth.message}</FormError>}
              {authErrors.google && <FormError>{authErrors.google}</FormError>}
            </Stack>
          </Box>
        </Stack>
      </form>
    </AuthCard>
  );
};
