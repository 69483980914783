import { useEffect } from "react";
import { CircularProgress, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { referralProgramApi } from "@src/pages/referral-program/apis";
import { parseAnbotoRequestError } from "@src/utils/parse-anboto-request-error";

type VerificationCodeProps = {
  referralCode: string;
  inviteCode: string;
  inviteEmail: string;
  inviteIsFromTeamAccount: boolean;
  onVerifyRefCode: (accountChoiceEnabled: boolean) => void;
  onVerifyInvite: (params: { isRegistered: boolean; accountName?: string }) => void;
};

export const VerificationCode = ({
  referralCode,
  inviteCode,
  inviteEmail,
  inviteIsFromTeamAccount,
  onVerifyRefCode,
  onVerifyInvite,
}: VerificationCodeProps) => {
  const [verify, { isLoading }] = anbotoApi.useVerifyInviteMutation();
  const [getCodeAvailability] = referralProgramApi.useLazyGetCodeAvailabilityQuery();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const verifyReferralCode = async () => {
    try {
      const verifyCode = await getCodeAvailability({ code: referralCode }).unwrap();
      const message = verifyCode.code_exists ? "Valid referral code" : "Invalid referral code";
      const variant = verifyCode.code_exists ? "success" : "error";

      snackbar.enqueueSnackbar(`${message} referral code`, {
        variant,
        ...(variant === "error" && { persist: true }),
      });

      if (verifyCode.code_exists) onVerifyRefCode(verifyCode.account_choice_enabled);
    } catch {
      snackbar.enqueueSnackbar("Invalid referral code", {
        persist: true,
        variant: "error",
      });
      navigate("/login");
    }
  };

  const verifyInviteCode = async () => {
    try {
      // check the invitation code
      // code - invitation code
      // email - email that invited
      // isAccount - 0 - if it's invitation from admin panel, 1 if it's invitation to team account from it's member
      const verifyResult = await verify({
        code: inviteCode,
        email: inviteEmail,
        is_account: inviteIsFromTeamAccount,
      }).unwrap();

      if (!verifyResult.success) {
        return snackbar.enqueueSnackbar("Invalid activation code", {
          persist: true,
          variant: "error",
        });
      }

      snackbar.enqueueSnackbar("Valid activation code", {
        variant: "success",
      });

      onVerifyInvite({ isRegistered: verifyResult.is_registered, accountName: verifyResult.account_name });
    } catch (error) {
      return snackbar.enqueueSnackbar(parseAnbotoRequestError(error), {
        persist: true,
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (referralCode) {
      verifyReferralCode();
    } else {
      verifyInviteCode();
    }
  }, [referralCode]);

  return isLoading ? (
    <Stack width="100%" justifyContent="center" alignItems="center" flex={1}>
      <CircularProgress size={30} />
    </Stack>
  ) : null;
};
