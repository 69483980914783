import { Navigate, Route } from "react-router-dom";
import { ProfileSettingsLazy } from "./profile-settings";
import { ApiSettingsLazy } from "./api-settings/api-settings.lazy";
import { AccountSettingsLazy } from "./account-settings/account-settings/account-settings.lazy";
import { ChangePasswordLazy } from "./change-password/change-password.lazy";
import { UserPreferencesLazy } from "./user-preferences/user-preferences.lazy";
import { TwoFaLazy } from "./two-fa/two-fa.lazy";
import { LinkedSettingsLazy } from "./linked-settings/linked-settings.lazy";
import { OrdersHistoryLazy } from "./transaction-history/orders-history.lazy";
import { OrderPreferencesFormLazy } from "./order-preferences/order-preferences-form.lazy";
import { FeeManagerLazy } from "./fee-manager/fee-manager.lazy";
import { HideInTestnet } from "@src/components/hide-in-testnet/hide-in-testnet";
import { Linked } from "@src/pages/settings/linked-settings";
import SettingsPage from "@src/pages/settings";

export const settingsRoutes = (
  <Route path="/settings/*" element={<SettingsPage />}>
    <Route path="api" element={<ApiSettingsLazy />} />
    <Route path="profile" element={<ProfileSettingsLazy />} />
    <Route path="account" element={<AccountSettingsLazy />} />
    <Route path="password" element={<ChangePasswordLazy />} />
    <Route path="user-preferences" element={<UserPreferencesLazy />} />
    <Route path="two-fa" element={<TwoFaLazy />} />
    <Route
      path="linked/*"
      element={
        <HideInTestnet>
          <Linked />
        </HideInTestnet>
      }
    >
      <Route path=":type" element={<LinkedSettingsLazy />} />
    </Route>

    <Route path="transactions-history" element={<OrdersHistoryLazy />} />
    <Route path="order-preferences" element={<OrderPreferencesFormLazy />} />

    <Route
      path="fees"
      element={
        <HideInTestnet>
          <FeeManagerLazy />
        </HideInTestnet>
      }
    />

    <Route path="*" element={<Navigate to="account" replace />} />
  </Route>
);
