/* eslint-disable react/no-unescaped-entities */
import { useRef, useState } from "react";
import { Box, Grid, Modal, Paper, Stack, styled, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ALogo from "@src/assets/svg/logo.svg?react";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { persistedStore } from "@src/store/persistedStore";
import { AppDispatch, RootState } from "@src/store/types";
import { logOutUser, setUser } from "@src/store/actions/user.action";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export const TermsModal = () => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  const userState = useSelector((state: RootState) => state.user);
  const [loading, setLoading] = useState<boolean>(false);

  const [termsAcknowledgement] = anbotoApi.useTermsAcknowledgementMutation();
  const [bottomReached, setBottomReached] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  const logout = async () => {
    await persistedStore.purge();
    dispatch(logOutUser());
  };

  const listInnerRef = useRef<HTMLDivElement>();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight + 15 >= scrollHeight) {
        setBottomReached(true);
      }
    }
  };

  const onIAgree = async () => {
    setLoading(true);
    try {
      await termsAcknowledgement({ user_uuid: userState.user_uuid }).unwrap();
      await dispatch(setUser({ ...userState, terms_of_use: true }));
      setSearchParams(searchParams, { replace: true });
      setOpen(false);
      setLoading(false);
    } catch {
      setLoading(false);
      console.log("error");
    }
  };

  return (
    <Modal open={open} disableAutoFocus={true} disableEnforceFocus={true} disableEscapeKeyDown={true}>
      <StyledBox>
        <Stack alignItems={"center"} mt={4} height={40}>
          <ALogo />
        </Stack>
        <Typography variant="h5" sx={{ textAlign: "center", paddingY: 3, fontWeight: "bold" }}>
          Welcome to Anboto
        </Typography>
        <Box
          ref={listInnerRef}
          onScroll={onScroll}
          sx={{ marginX: 0.5, paddingX: 3, height: "400px", overflowY: "auto" }}
        >
          <Paper sx={{ padding: 2, fontSize: 14, backgroundColor: theme.palette.background.default }}>
            <Typography variant="h6" sx={{ color: "text.secondary", mb: 4, mt: 1, textAlign: "center" }}>
              Acknowledge terms
            </Typography>
            By creating an account on trade.anboto.xyz and using Anboto software to execute your trades, you agree to
            the Terms of Use, and the Conditions above: <br />
            <br />
            It appears that you are accessing trade.anboto.xyz from outside the United States or an unrestricted
            territory. You hereby agree, represent and warrant that: <br />
            <br />
            <li>
              You are not a person or company who is a resident of, or is located, incorporated or has a registered
              agent in, the United States or a restricted location.
            </li>
            <br />
            <li>
              You will not in the future access this site or trade on trade.anboto.xyz while located within the United
              States or a restricted location.
            </li>
            <br />
            <li>
              You are lawfully permitted to access this site and trade on trade.anboto.xyz under the laws of the
              jurisdiction in which you reside and are located,
            </li>
            <br />
            <li>You understand the risks associated with entering into perpetual contracts and using leverage.</li>
            <br />
            <li>
              You understand that trade.anboto.xyz is not registered or licensed by any regulatory agency or authority.
              No such agency or authority has reviewed or approved the use of Anboto Labs developed software.
            </li>
            <br />
            <li>
              You understand that trade.anboto.xyz is identified as a “BETA Software”. You are in no obligation to use
              any Beta Software. Because Beta Sotware can be at various stages of development, operation and use of the
              Beta Software may be unpredictable. You understand that this Beta Software is provided to You "AS IS"
              without any warranty. You acknowledge that: (1) Beta Software has not been fully tested and may contain
              errors and bugs (2) Use or operation of Beta Software should not occur in a production environment (3)
              Anboto Labs and trade.anboto.xyz will not be liable for any bugs or losses occurring by your usage of the
              the Beta Software.
            </li>
          </Paper>
        </Box>
        <Stack gap={1} width="100%" display="flex" flexDirection="row" justifyContent="center" padding={5}>
          <Grid item width="100%" display="flex" flexDirection="row">
            <AnbotoButton
              sx={{ marginRight: "5px", "&:focus": { outline: 1 } }}
              size="large"
              variant="outlined"
              fullWidth
              disabled={!bottomReached}
              type="submit"
              onClick={() => logout()}
            >
              Deny
            </AnbotoButton>
            <AnbotoButton
              sx={{ marginLeft: "5px", "&:focus": { outline: 1 } }}
              size="large"
              variant="contained"
              fullWidth
              disabled={!bottomReached}
              loading={loading}
              type="submit"
              onClick={() => onIAgree()}
            >
              I agree
            </AnbotoButton>
          </Grid>
        </Stack>
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 505,
  height: 680,
  border: "0px solid",
  borderColor: "#232C2F",
  backgroundColor: "#232C2F",
  borderRadius: 8,
});
