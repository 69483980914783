import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material";

type PostTradeHeaderInfoProps = {
  iconSize: number;
  title: string;
  isFilled?: boolean;
  color?: string;
};

function AnbotoInfoIcon({ iconSize, title, isFilled, color }: PostTradeHeaderInfoProps) {
  const theme = useTheme();
  return (
    <Tooltip title={title}>
      {isFilled ? (
        <InfoIcon sx={{ fontSize: iconSize, cursor: "pointer", color: theme.palette.text.secondary }} color="primary" />
      ) : (
        <InfoOutlinedIcon
          sx={{ fontSize: iconSize, cursor: "pointer", color: color ? color : theme.palette.text.secondary }}
          color="primary"
        />
      )}
    </Tooltip>
  );
}

export default AnbotoInfoIcon;
