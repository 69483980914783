import React, { useState } from "react";
import { Box, Modal, Stack, styled, Typography } from "@mui/material";
import AnbotoLogo from "@src/assets/svg/A-graphic.svg?react";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";
import { anbotoApi } from "@src/store/apis/anbotoApi";

export const WelcomeModal = () => {
  const [open, setOpen] = useState(true);
  const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);

  const onCreateAccount = async () => {
    await getUserDetailsQuery;
    setOpen(false);
  };

  return (
    <Modal open={open} disableAutoFocus={true} disableEnforceFocus={true} disableEscapeKeyDown={true}>
      <StyledBox>
        <Box sx={{ display: "flex", justifyContent: "center", paddingTop: 4 }}>
          <AnbotoLogo />
        </Box>
        <Typography variant="h5" sx={{ textAlign: "center", paddingTop: 3 }}>
          Welcome to ANBOTO
        </Typography>
        <Typography sx={{ mt: 2, textAlign: "center" }}>
          Let’s create your trading account. <br /> Additionally you can invite a teammate to join.
        </Typography>
        <Stack alignItems="center" mt={5} paddingX={15}>
          <AnbotoButton sx={{ marginX: 10 }} variant="contained" fullWidth onClick={() => onCreateAccount()}>
            Create account
          </AnbotoButton>
        </Stack>
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 505,
  height: 418,
  border: "0px solid",
  borderColor: "#232C2F",
  backgroundColor: "#232C2F",
  borderRadius: 8,
});
