import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  styled,
  MenuItem,
  Box,
  IconButton,
  useTheme,
  Typography,
  Stack,
  SwipeableDrawer,
  ListItem,
  useMediaQuery,
  Divider,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SettingsIcon from "@mui/icons-material/Settings";
import { InfoOutlined } from "@mui/icons-material";
import { MainMenuDropdown } from "../main-menu-dropdown";
import { ToggleWatchlistTopBar } from "./toggle-watchlist-top-bar";
import { SwitchSidebar } from "./switch-sidebar";
import WidgetsIcon from "./icons/widgets-icon.svg?react";
import AnbotoLogo from "./icons/anboto.svg?react";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { TeamSelect } from "@src/components/sidebar/team-select";
import { SidebarMenu } from "@src/components/sidebar-menu";
import { getSideMenuItems } from "@src/pages/settings";
import { CreditsBalance } from "@src/components/sidebar/credits-balance";
import { WidgetsMenu } from "@src/features/widgets-layout/components/widgets-menu/widgets-menu";
import { Auth } from "@src/features/auth";
import { useTestnetEnabled } from "@src/hooks/use-testnet-enabled";
import { useAppDispatch, useAppSelector } from "@src/store/hooks";
import {
  setIsCefiIntroShown,
  setIsDefiIntroShown,
  setIsIntroShowing,
  setIsWidgetsIntroShown,
} from "@src/store/actions/user.action";
import { usePermissions } from "@src/hooks/use-permissions";
import { VIEW_ONLY_MODE_DESCRIPTION } from "@src/constants/common";
import { useInitialPath } from "@src/components/initial-redirect";

const MainNavbar = () => {
  const dispatch = useAppDispatch();
  const path = useInitialPath();
  const getUserDetailsQuery = anbotoApi.useGetUserDetailsQuery(null);
  const getAccountsQuery = anbotoApi.useGetUserAccountsQuery(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const settingsOpen = Boolean(anchorEl);

  const isIntroShowing = useAppSelector((state) => state.user.isIntroShowing);
  const permissions = usePermissions();

  useEffect(() => {
    if (isIntroShowing) {
      handleSettingsClose();
    }
  }, [isIntroShowing]);

  const theme = useTheme();

  const isTestnet = useTestnetEnabled();

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSettingsClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => Auth.signOut();

  const menu = getSideMenuItems({ linkPreffix: "settings" });
  const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const isOnlyIndividualAccount =
    getUserDetailsQuery?.data?.is_individual_account && getAccountsQuery?.data?.count === 1;

  const onShowIntroClick = () => {
    dispatch(setIsDefiIntroShown(false));
    dispatch(setIsCefiIntroShown(false));
    dispatch(setIsWidgetsIntroShown(false));
    dispatch(setIsIntroShowing(true));
  };

  return (
    <Stack
      height={48}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      position="relative"
      zIndex={10}
    >
      <Stack direction="row" gap={2.5} pl={2} alignItems="center" height="100%">
        <NavLink
          to={path}
          style={{
            height: "20px",
            position: "relative",
            flexDirection: "row",
            opacity: 0.99,
            display: "inline",
          }}
        >
          <AnbotoLogo width="auto" height={16} />
        </NavLink>
        <MainMenuDropdown />
        {!isTestnet && !permissions.trade && (
          <Tooltip title={VIEW_ONLY_MODE_DESCRIPTION}>
            <Stack
              direction="row"
              sx={{ userSelect: "none", background: (theme) => theme.custom.background.default }}
              gap={0.5}
              px={1}
              py={0.5}
              alignItems="center"
              borderRadius={0.5}
            >
              <Typography
                variant="body2"
                fontSize={13}
                fontWeight="bold"
                sx={{ color: (theme) => theme.custom.text.dim }}
              >
                View only mode
              </Typography>
              <InfoOutlined sx={{ fontSize: 12, color: (theme) => theme.custom.text.dim }} />
            </Stack>
          </Tooltip>
        )}
      </Stack>

      <Stack direction="row" alignItems="center">
        {isTestnet && (
          <Typography variant="body2" fontWeight="bold" sx={{ color: (theme) => theme.palette.success.dark }}>
            Testnet
          </Typography>
        )}
        <Stack className="fee-wallet-header-container">
          {!isTestnet && getUserDetailsQuery?.data?.fee_manager_enabled && <CreditsBalance />}
        </Stack>

        {!isOnlyIndividualAccount && (
          <Box mx={1.5}>
            <TeamSelect />
          </Box>
        )}

        <WidgetsMenu>
          <IconButton className="widgets-icon-button" sx={{ flexShrink: 0 }}>
            <WidgetsIcon />
          </IconButton>
        </WidgetsMenu>

        <IconButton
          sx={{ color: "#8A9296" }}
          onClick={handleSettingsClick}
          className="settings-icon-button"
          aria-controls={settingsOpen ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={settingsOpen ? "true" : undefined}
        >
          <SettingsIcon sx={{ fontSize: 20 }} />
        </IconButton>

        <SwipeableDrawer
          anchor="right"
          open={settingsOpen}
          onClose={handleSettingsClose}
          onOpen={handleSettingsClick}
          disableSwipeToOpen
          PaperProps={{
            sx: {
              backgroundColor: theme.custom.background.darkGrey,
            },
          }}
        >
          <Box
            sx={{
              width: 240,
              height: !isBigScreen ? "max-content" : null,
            }}
          >
            <ListItem sx={{ pl: 1 }}>
              <ToggleSidebarButton onClick={handleSettingsClose}>
                <ArrowForwardIcon sx={{ width: 20, height: 20 }} />
              </ToggleSidebarButton>
              <Typography sx={{ ml: 1, fontWeight: 500 }}>Settings</Typography>
            </ListItem>

            <ListSubheader
              disableSticky
              sx={{ fontSize: 18, height: "40px", backgroundColor: theme.custom.background.darkGrey }}
            >
              View
            </ListSubheader>

            <SwitchSidebar />

            <ToggleWatchlistTopBar />

            <DividerLine />

            <SidebarMenu menu={menu} swipeableMode={true} toggleSidebar={handleSettingsClose} />

            <DividerLine />

            {!isTestnet && (
              <Stack direction="row" justifyContent="space-between">
                <StyledMenuItem onClick={onShowIntroClick} sx={{ flex: 1 }}>
                  Show intro
                </StyledMenuItem>
              </Stack>
            )}

            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ backgroundColor: theme.custom.background.secondary }}
            >
              <StyledMenuItem onClick={onLogoutClick} sx={{ flex: 1, color: "#C3462B" }}>
                Logout
              </StyledMenuItem>
            </Stack>
          </Box>
        </SwipeableDrawer>
      </Stack>
    </Stack>
  );
};

const DividerLine = styled(Divider)(({ theme }) => ({
  border: `0.5px solid ${theme.custom.background.default}`,
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  margin: theme.spacing(0.5),
  fontSize: 14,
  height: 40,
  padding: "12px 16px",
  borderRadius: 4,
  textAlign: "left",
  color: "#8A9296",
  "&:hover": {
    fontWeight: 500,
    color: "white",
  },
}));

const ToggleSidebarButton = styled(IconButton)({
  "& .MuiSvgIcon-root": {
    color: "#FFFFFF",
  },
  "&:hover .MuiSvgIcon-root": {
    color: "#8A9296",
  },
});

export { MainNavbar };
