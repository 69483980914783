export enum MenuItemId {
  Cex = "cex",
  Dex = "dex",
  Otc = "otc",
  OrderPreferences = "order-preferences",
  TransactionHistory = "transaction-history",
  Portfolio = "portfolio",
  Metrics = "metrics",
  PostTradeAnalysis = "post-trade-analysis",
  Account = "account",
  LinkedCexs = "linked-cexs",
  LinkedOtc = "linked-otc",
  DefiWallets = "defi-wallets",
  FeeWallets = "fee-wallets",
  Affiliate = "affiliate",
  Competitions = "competitions",
  Api = "api",
  Documentation = "documentation",
  Support = "support",
  FundingRates = "funding-rates",
  Profile = "profile",
  Password = "password",
  TwoFa = "2fa",
  Analytics = "analytics",
  FeesDashboard = "fees-dashboard",
  AdminDashboard = "admin-dashboard",
}
