import { WidgetsGridView, WidgetName } from "../../types";
import OrderbookIcon from "./icons/orderbook.svg?react";
import OrdersTableFullIcon from "./icons/orders-table.svg?react";
import PriceImpactIcon from "./icons/price-impact.svg?react";
import SymbolChartIcon from "./icons/symbol-chart.svg?react";
import OrderFormIcon from "./icons/order-form.svg?react";

export const MENU_ITEMS: { [K in WidgetsGridView]: WidgetName[] } = {
  [WidgetsGridView.CEFI]: [
    WidgetName.CefiOrderForm,
    WidgetName.CefiSymbolChart,
    WidgetName.CefiOrdersTableFull,
    WidgetName.CefiPriceImpact,
    WidgetName.CefiOrderbook,
  ],
  [WidgetsGridView.DEFI]: [WidgetName.DefiSymbolChart, WidgetName.DefiOrdersTableFull],
  [WidgetsGridView.OTC]: [WidgetName.OtcOrderForm, WidgetName.OtcSymbolChart, WidgetName.OtcOrdersTableFull],
};

export const MENU_ITEM_LABEL: Record<WidgetName, string> = {
  [WidgetName.CefiOrderForm]: "Order form",
  [WidgetName.CefiSymbolChart]: "Symbol chart",
  [WidgetName.CefiPriceImpact]: "Price impact",
  [WidgetName.CefiOrdersTableFull]: "Orders table",
  [WidgetName.CefiOrderbook]: "Orderbook",

  [WidgetName.DefiOrderForm]: "Order form",
  [WidgetName.DefiSymbolChart]: "Symbol chart",
  [WidgetName.DefiOrdersTableFull]: "Orders table",

  [WidgetName.OtcOrderForm]: "Order form",
  [WidgetName.OtcSymbolChart]: "Symbol chart",
  [WidgetName.OtcOrdersTableFull]: "Orders table",
};

export const ICONS = {
  [WidgetName.CefiOrderForm]: OrderFormIcon,
  [WidgetName.CefiSymbolChart]: SymbolChartIcon,
  [WidgetName.CefiPriceImpact]: PriceImpactIcon,
  [WidgetName.CefiOrdersTableFull]: OrdersTableFullIcon,
  [WidgetName.CefiOrderbook]: OrderbookIcon,

  [WidgetName.DefiOrderForm]: OrderFormIcon,
  [WidgetName.DefiSymbolChart]: SymbolChartIcon,
  [WidgetName.DefiOrdersTableFull]: OrdersTableFullIcon,

  [WidgetName.OtcOrderForm]: OrderFormIcon,
  [WidgetName.OtcSymbolChart]: SymbolChartIcon,
  [WidgetName.OtcOrdersTableFull]: OrdersTableFullIcon,
};
