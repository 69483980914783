import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MarketType } from "@src/features/exchanges/types";
import { GetOrdersPeriod } from "@src/store/apis/anbotoApi/types";

export type GroupBy = "days" | "weeks" | "months";

export interface Metrics {
  filterOptions: {
    strategies: string[];
    exchanges: string[];
    assetClasses: MarketType[];
    type: string[];
    subaccounts: string[];
    sides: string[];
    groupBy: string;
  };
  tradesMetricsFilters: {
    tradeTypes: string[];
    strategies: string[];
    assetClasses: string[];
    exchanges: string[];
    groupBy: GroupBy;
    sides: string[];
    subaccounts: string[];
  };
  historyFilters: {
    tradeTypes: string[];
    strategies: string[];
    sides: string[];
    exchanges: string[];
    period: GetOrdersPeriod;
  };
}

const initialState: Metrics = {
  filterOptions: {
    strategies: [],
    exchanges: [],
    assetClasses: [MarketType.SPOT, MarketType.FUTURE],
    type: ["CEFI", "DEFI"],
    subaccounts: [],
    sides: ["sell", "buy"],
    groupBy: "months",
  },
  tradesMetricsFilters: {
    subaccounts: [],
    tradeTypes: [],
    strategies: [],
    assetClasses: [],
    exchanges: [],
    groupBy: "months",
    sides: [],
  },
  historyFilters: {
    tradeTypes: [],
    strategies: [],
    sides: [],
    exchanges: [],
    period: GetOrdersPeriod.All,
  },
};

export const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    setTradesGroupBy: (state, action: PayloadAction<GroupBy>) => {
      state.tradesMetricsFilters.groupBy = action.payload;
    },
    setTradesFiltersTradeType: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.tradeTypes = action.payload;
    },
    setTradesFiltersStrategy: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.strategies = action.payload;
    },
    setTradesFiltersAssetsClass: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.assetClasses = action.payload;
    },
    setTradesFiltersExchanges: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.exchanges = action.payload;
    },
    setTradesFiltersSides: (state, action: PayloadAction<string[]>) => {
      state.tradesMetricsFilters.sides = action.payload;
    },

    setHistoryFiltersTradeType: (state, action: PayloadAction<string[]>) => {
      state.historyFilters.tradeTypes = action.payload;
    },
    setHistoryFiltersStrategy: (state, action: PayloadAction<string[]>) => {
      state.historyFilters.strategies = action.payload;
    },
    setHistoryFiltersSides: (state, action: PayloadAction<string[]>) => {
      state.historyFilters.sides = action.payload;
    },
    setHistoryFiltersExchanges: (state, action: PayloadAction<string[]>) => {
      state.historyFilters.exchanges = action.payload;
    },
    setHistoryPeriod: (state, action: PayloadAction<GetOrdersPeriod>) => {
      state.historyFilters.period = action.payload;
    },
    setFilterOptions: (state, action: PayloadAction<Partial<Metrics["filterOptions"]>>) => {
      Object.keys(action.payload).forEach((key) => (state.filterOptions[key] = action.payload[key]));
    },
    setFilters: (state, action: PayloadAction<Partial<Metrics["tradesMetricsFilters"]>>) => {
      Object.keys(action.payload).forEach((key) => (state.tradesMetricsFilters[key] = action.payload[key]));
    },
  },
});

export const {
  setTradesFiltersTradeType,
  setTradesFiltersExchanges,
  setTradesFiltersStrategy,
  setTradesFiltersAssetsClass,
  setTradesFiltersSides,
  setHistoryFiltersStrategy,
  setHistoryFiltersTradeType,
  setHistoryFiltersSides,
  setHistoryFiltersExchanges,
  setHistoryPeriod,
  setTradesGroupBy,
  setFilterOptions,
  setFilters,
} = metricsSlice.actions;

export default metricsSlice.reducer;
