import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmSignUpOutput, SignInOutput, confirmSignUp } from "aws-amplify/auth";
import { useSnackbar } from "notistack";
import { ConfirmEmail } from "../registration/components/confirm-email";
import { LoginForm } from "./login-form";
import { SetupPassword } from "./setup-password";
import { SetupOtp } from "./setup-otp";
import { VerifyOtp } from "./verify-otp";
import { anbotoApi } from "@src/store/apis/anbotoApi";
import { useAuth } from "@src/features/auth/hooks/use-auth";
import { Auth } from "@src/features/auth";
import { APP_NAME } from "@src/constants/common";
import { useOtpQr } from "@src/features/auth/hooks/use-otp-qr";

enum LoginStep {
  GENERAL_LOGIN = "general_login",
  CONFIRM_EMAIL = "confirm_email",
  SETUP_PASSWORD = "setup_password",
  TWO_FACTOR_SETTINGS = "two_factor_settings",
  TWO_FACTOR_AUTH_CODE = "two_factor_auth_code",
  TWO_FACTOR_SETUP = "two_factor_setup",
}

type FieldKey = "signIn" | "google" | "setupPassword" | "addToTheTeam" | "otp" | "emailVerifyCode" | "email";

export const Login = () => {
  const snackbar = useSnackbar();
  const [searchParams] = useSearchParams();
  const { signIn, verifyTOTPSetup, updateMFAPreference, refetchSession, authenticated } = useAuth();
  const navigate = useNavigate();

  const paramsEmail = searchParams.get("email") || "";
  const paramsTeamInviteCode = searchParams.get("team_invite_code") || "";

  const [acceptInvite, { isLoading: acceptInviteLoading }] = anbotoApi.useAddParticipantMutation();

  const [loading, setLoadingState] = useState<Record<FieldKey, boolean>>({} as Record<FieldKey, boolean>);
  const [step, setStep] = useState(LoginStep.GENERAL_LOGIN);

  const [authCreds, setAuthCreds] = useState<{ email: string; password: string }>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<Record<FieldKey, string>>({} as Record<FieldKey, string>);
  const [otpUri, setOtpUri] = useState("");
  const qr = useOtpQr();

  const setError = (key: string, value: string) => {
    setErrors((errors) => ({ ...errors, [key]: value }));
  };

  const setLoading = (key: FieldKey, value: boolean) => setLoadingState((loading) => ({ ...loading, [key]: value }));

  const handleNextStep = (output: SignInOutput | ConfirmSignUpOutput) => {
    if ("isSignUpComplete" in output) {
      switch (output.nextStep.signUpStep) {
        case "CONFIRM_SIGN_UP":
          return setStep(LoginStep.CONFIRM_EMAIL);
        case "DONE":
          return onLoginFormSubmit({ email: authCreds.email, password: authCreds.password });
      }
    }

    if ("isSignedIn" in output) {
      if (output.isSignedIn) return onLoginSuccess();

      switch (output.nextStep.signInStep) {
        case "CONFIRM_SIGN_UP":
          return setStep(LoginStep.CONFIRM_EMAIL);

        case "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED":
          return setStep(LoginStep.SETUP_PASSWORD);

        case "CONTINUE_SIGN_IN_WITH_TOTP_SETUP":
          setOtpUri(output.nextStep.totpSetupDetails.getSetupUri(APP_NAME, authCreds.email).toString());

          return setStep(LoginStep.TWO_FACTOR_SETUP);

        case "CONFIRM_SIGN_IN_WITH_TOTP_CODE":
          return setStep(LoginStep.TWO_FACTOR_AUTH_CODE);

        case "RESET_PASSWORD":
          return handlePasswordReset();

        default:
          return setStep(LoginStep.GENERAL_LOGIN);
      }
    }
  };

  const onLoginFormSubmit = async ({ email, password }) => {
    setAuthCreds({ email, password });
    setLoading("signIn", true);

    try {
      const signInOutput = await signIn({ username: email, password });

      handleNextStep(signInOutput);
    } catch (err) {
      console.log({ err });

      setError("email", err.message);
    } finally {
      setLoading("signIn", false);
    }
  };

  const onConfirmEmailSubmit = async (code: string) => {
    setLoading("emailVerifyCode", true);

    try {
      const output = await confirmSignUp({
        username: authCreds.email,
        confirmationCode: code,
      });

      handleNextStep(output);
    } catch (error) {
      setError("emailVerifyCode", error.message);
    } finally {
      setLoading("emailVerifyCode", false);
    }
  };

  const addUserToTheTeam = async () =>
    acceptInvite({
      user_email: authCreds.email,
      invite_code: paramsTeamInviteCode,
    }).unwrap();

  const onSetupPasswordSubmit = async (password: string) => {
    try {
      setLoading("setupPassword", true);

      const output = await Auth.confirmSignIn({
        challengeResponse: password,
      });

      setLoading("setupPassword", false);

      handleNextStep(output);
    } catch (error) {
      setLoading("setupPassword", false);

      setError("setupPassword", error.message);
    }
  };

  const onOtpCodeSubmit = async (code: string) => {
    try {
      setLoading("otp", true);

      const output = await Auth.confirmSignIn({
        challengeResponse: code,
      });

      setLoading("otp", false);

      handleNextStep(output);
    } catch (error) {
      setLoading("otp", false);

      setError("otp", error.message);
    }
  };

  const onOtpSettingsSubmit = async (code: string) => {
    try {
      setLoading("otp", true);

      await verifyTOTPSetup({ code });
      await updateMFAPreference({ totp: "ENABLED" });
      await refetchSession();

      snackbar.enqueueSnackbar("Two-factor authentication has been enabled", { variant: "success" });

      navigate("/");
    } catch (error) {
      setError("otp", error.message);
    } finally {
      setLoading("otp", false);
    }
  };

  const onLoginSuccess = async () => {
    setLoading("otp", true);

    if (paramsTeamInviteCode) {
      try {
        await addUserToTheTeam();

        setLoading("otp", false);

        return navigate("/settings/account");
      } catch {
        snackbar.enqueueSnackbar("Add to the team error", { variant: "error" });
      }
    }

    try {
      const mfa = await Auth.fetchMFAPreference();

      if (!mfa?.enabled) {
        setLoading("otp", false);

        return setStep(LoginStep.TWO_FACTOR_SETTINGS);
      }
    } catch {
      setLoading("otp", false);
    }

    return navigate("/");
  };

  const handlePasswordReset = () => {
    navigate(`/forgot-password?email=${authCreds.email}`);
  };

  const isLoading = Object.values(loading).some(Boolean) || acceptInviteLoading;

  useEffect(() => {
    if (authenticated) navigate("/");
  }, []);

  return (
    <>
      {step === LoginStep.GENERAL_LOGIN && (
        <LoginForm loading={isLoading} onSubmit={onLoginFormSubmit} authErrors={errors} defaultEmail={paramsEmail} />
      )}
      {step === LoginStep.CONFIRM_EMAIL && (
        <ConfirmEmail
          onSubmit={(code) => onConfirmEmailSubmit(code)}
          error={errors.emailVerifyCode}
          loading={isLoading}
        />
      )}
      {step === LoginStep.SETUP_PASSWORD && (
        <SetupPassword onSubmit={onSetupPasswordSubmit} error={errors.setupPassword} loading={isLoading} />
      )}
      {/* if mfa is mandatory and required in sign in flow */}
      {step === LoginStep.TWO_FACTOR_SETUP && (
        <SetupOtp onSubmit={onOtpCodeSubmit} uri={otpUri} loading={isLoading} error={errors.otp} />
      )}
      {/* if mfa is optional we show this step, user can skip it */}
      {step === LoginStep.TWO_FACTOR_SETTINGS && (
        <SetupOtp
          onSubmit={onOtpSettingsSubmit}
          uri={qr.uri}
          loading={isLoading}
          error={errors.otp}
          onSkip={() => navigate("/")}
        />
      )}
      {step === LoginStep.TWO_FACTOR_AUTH_CODE && (
        <VerifyOtp onSubmit={onOtpCodeSubmit} loading={isLoading} error={errors.otp} />
      )}
    </>
  );
};
