import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { RouterProvider } from "react-router-dom";
import { AnbotoGlobalLoader } from "./components/anboto-global-loader";
import { appRouter } from "./app-router";
import { AuthenticatorProvider } from "@src/features/auth";
import { muiTheme } from "@src/components/theme/mui-theme";
import { store } from "@src/store/store";
import { persistedStore } from "@src/store/persistedStore";
import { NotificationBar } from "@src/components/notification-bar";

type anbotoNotification = {
  title?: string;
  buttonAction?(): void;
  actionName?: string;
};

declare module "notistack" {
  interface VariantOverrides {
    success: anbotoNotification;
    error: anbotoNotification;
    info: anbotoNotification;
    warning: anbotoNotification;
  }
}

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistedStore}>
        <AuthenticatorProvider>
          <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline enableColorScheme />
              <SnackbarProvider
                Components={{
                  success: NotificationBar,
                  error: NotificationBar,
                  warning: NotificationBar,
                  info: NotificationBar,
                }}
                autoHideDuration={5000}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <AnbotoGlobalLoader>
                  <RouterProvider router={appRouter} />
                </AnbotoGlobalLoader>
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </AuthenticatorProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
