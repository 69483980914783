import _pick from "lodash/pick";
import { AnbotoSubscription, AnbotoSubscriptionOptions } from "../anboto-subscription";
import { MarketDataMessage, MarketDataSubscribeData, MarketDataSubscriptionType } from "../types";

const idKeys = ["data_type", "exchange", "market_type", "params", "symbol"];

export class MarketDataSubsctiption extends AnbotoSubscription<MarketDataSubscribeData, MarketDataMessage> {
  constructor(opts: AnbotoSubscriptionOptions) {
    super(opts);
  }

  getId = (data: MarketDataSubscribeData) => {
    if (this.isOrderbok(data.data_type)) {
      return this.getOrderbookId(data);
    }

    return JSON.stringify(_pick(data, idKeys));
  };

  getIdFromMsg = (data: MarketDataMessage): string => {
    if (this.isOrderbok(data.data_type)) {
      return this.getOrderbookId(data);
    }

    return JSON.stringify(_pick(data, idKeys));
  };

  isOrderbok = (dataType: MarketDataSubscriptionType) =>
    [MarketDataSubscriptionType.ORDERBOOK, MarketDataSubscriptionType.OTC_ORDERBOOK].includes(dataType);

  getOrderbookId = (data: MarketDataSubscribeData) => JSON.stringify(_pick({ ...data, params: {} }, idKeys));

  transformMsg = (message: MarketDataMessage) => message.data;
}
