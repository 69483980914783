import { Stack, styled } from "@mui/material";
import linesUrl from "@src/pages/auth/assets/auth-lines.svg";

export const AuthLayout = ({ children }: any) => {
  return <Root>{children}</Root>;
};

const Root = styled(Stack)(({ theme }) => ({
  background: `center / cover no-repeat ${theme.custom.background.default} url("${linesUrl}")`,
  height: "100vh",
  overflow: "auto",
}));
