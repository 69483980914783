import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemProps,
  ListItemText,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  typographyClasses,
} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import { Link } from "@src/components/sidebar-menu/link";
import { styledOptions } from "@src/utils/styled-options";
import { ExtraProps } from "@src/components";

type SidebarMenuItemProps = {
  compact: boolean;
  isVertical: boolean;
  url: string;
  icon: React.ReactElement | undefined;
  iconActive: React.ReactElement | undefined;
  label: string;
  toggleSidebar?: () => void;
  external?: boolean;
  component?: React.ElementType<any>;
  className?: string;
} & ListItemProps;

const SidebarMenuItem = ({
  component,
  compact,
  isVertical,
  url,
  external,
  icon,
  iconActive,
  label,
  toggleSidebar,
  sx,
  className,
  onClick,
}: SidebarMenuItemProps) => {
  const location = useLocation();
  const active = !!url && location.pathname.includes(url);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  return (
    <StyledListItem
      compact={compact}
      disableGutters
      components={{ Root: component || Link }}
      componentsProps={{ root: { to: url, external } as any }}
      vertical={isVertical}
      key={url}
      sx={{ display: "flex", ...sx }}
      onMouseOver={() => setTooltipVisible(true)}
      onMouseOut={() => setTooltipVisible(false)}
      onClick={(e) => {
        onClick && onClick(e);
        setTooltipVisible(false);
      }}
      className={className}
    >
      <StyledListItemButton compact={compact} active={active}>
        {compact ? (
          <Stack>
            {active ? (
              <SidebarMenuTooltip
                placement="right"
                title={label}
                open={tooltipVisible}
                TransitionProps={{ timeout: 0 }}
              >
                {iconActive || <CircleIcon />}
              </SidebarMenuTooltip>
            ) : (
              <SidebarMenuTooltip
                placement="right"
                title={label}
                open={tooltipVisible}
                TransitionProps={{ timeout: 0 }}
              >
                {icon || <CircleIcon />}
              </SidebarMenuTooltip>
            )}
          </Stack>
        ) : (
          <>
            <Stack> {active ? iconActive : icon} </Stack>
            <ListItemText
              primary={label}
              onClick={toggleSidebar}
              primaryTypographyProps={{
                fontSize: 14,
                fontWeight: active ? 600 : "normal",
              }}
            />
          </>
        )}
      </StyledListItemButton>
    </StyledListItem>
  );
};

const StyledListItem = styled(
  ListItem,
  styledOptions
)<ExtraProps>(({ vertical, compact }) => ({
  color: "#8A9296",
  textDecoration: "none",
  position: "relative",
  "&.active:after": {
    content: `''`,
    display: "block",
    height: vertical ? 16 : 3,
    width: vertical ? 4 : 33,
    position: "absolute",
    top: vertical ? "50%" : undefined,
    bottom: vertical ? undefined : 0,
    right: vertical ? 0 : "50%",
    transform: vertical ? "translateY(-50%)" : "translateX(50%)",
    background: compact ? undefined : "#2C72E3",
  },
  [`&.active .${typographyClasses.root}`]: {
    color: "#2C72E3",
    fontWeight: "bold",
  },
}));

const StyledListItemButton = styled(
  ListItemButton,
  styledOptions
)<ExtraProps>(({ compact, active, theme }) => ({
  paddingLeft: compact ? 0 : theme.spacing(2),
  paddingRight: compact ? 0 : theme.spacing(1),
  margin: 0,
  justifyContent: compact ? "center" : undefined,
  borderRadius: 4,
  background: active ? "#192022" : undefined,
  height: compact ? 38 : undefined,
}));

const SidebarMenuTooltip = styled(Tooltip)({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: "8px 16px",
    fontSize: 12,
    background: "rgba(97, 97, 97, 1)",
    marginLeft: -1,
  },
});

export default SidebarMenuItem;
