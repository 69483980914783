import { Stack } from "@mui/material";
import { PredefinedWidgetsLayout, WidgetPredefinedLayoutView } from "../../types";
import { cefiPageLayoutBasic } from "../../layouts/cefi-page-layout-basic";
import { cefiPageLayoutAdvance } from "../../layouts/cefi-page-layout-advance";
import { LayoutButton } from "./layout-button";
import { useAppSelector } from "@src/store/hooks";

export const Layouts = ({
  onLayoutClick,
  onDeleteClick,
  onEditClick,
}: {
  onLayoutClick: (layout: PredefinedWidgetsLayout) => void;
  onDeleteClick: (id: string) => void;
  onEditClick: (layout: PredefinedWidgetsLayout) => void;
}) => {
  const layouts = useAppSelector((state) => state.user.user_layouts);

  return (
    <>
      <Stack m={2}>
        <Stack flexDirection="row" flexWrap="wrap" justifyContent="space-between">
          <LayoutButton
            title="Basic"
            description="Single symbol"
            type={WidgetPredefinedLayoutView.BASIC}
            onClick={() => onLayoutClick(cefiPageLayoutBasic)}
            width={125}
          />
          <LayoutButton
            title="Advanced"
            description="Multi-symbol"
            type={WidgetPredefinedLayoutView.ADVANCE}
            onClick={() => onLayoutClick(cefiPageLayoutAdvance)}
            width={125}
          />
        </Stack>
        {!!layouts?.length && (
          <Stack direction="row" gap={2} flexWrap="wrap" mt={2}>
            {layouts.map((layout: PredefinedWidgetsLayout) => (
              <LayoutButton
                key={layout.id}
                title={layout.name}
                type={WidgetPredefinedLayoutView.BASIC}
                onDelete={() => onDeleteClick(layout.id)}
                onClick={() => onLayoutClick(layout)}
                onEdit={() => onEditClick(layout)}
              />
            ))}
          </Stack>
        )}
      </Stack>
    </>
  );
};
