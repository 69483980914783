import { getChainIdHex } from "./hex-utils";
import { ChainId, ChainConfig, Token, GasPriceOptionType } from "@src/pages/defi/types";
import { CHAIN_ID_DEFAULT_TOKENS } from "@src/constants/chainIdDetaultTokens";
import {
  DefiOrderExecutionStrategy,
  DefiOrderExpiration,
  OrderExecutionStrategy,
  OrderPreferencesExpirations,
  OrderTradingDurationUnit,
  OrderType,
} from "@src/store/apis/anbotoApi/types";
import ethereumUrl from "@src/assets/svg/crypto_networks/1.svg";
import bscUrl from "@src/assets/svg/crypto_networks/56.svg";
import polygonUrl from "@src/assets/svg/crypto_networks/137.svg";
import avalancheUrl from "@src/assets/svg/crypto_networks/43114.svg";
import arbitrumUrl from "@src/assets/svg/crypto_networks/42161.svg";
import optimismUrl from "@src/assets/svg/crypto_networks/10.svg";
import baseUrl from "@src/assets/svg/crypto_networks/8453.svg";
import kyberswapUrl from "@src/assets/svg/defi-exchanges/kyberswap.svg";
import uniswapUrl from "@src/assets/svg/defi-exchanges/uniswap.svg";
import paraswapUrl from "@src/assets/svg/defi-exchanges/paraswap.svg";
import okx_dexUrl from "@src/assets/svg/defi-exchanges/okx.svg";
import openoceanUrl from "@src/assets/svg/defi-exchanges/openocean.svg";
import odosUrl from "@src/assets/svg/defi-exchanges/odos.svg";
import zeroXUrl from "@src/assets/svg/defi-exchanges/0x.svg";
import oneInchUrl from "@src/assets/svg/defi-exchanges/1inch.svg";

export const DEFAULT_DEFI_SLIPPAGE = "1";
export const DEFAULT_DEFI_MULTISLICE_SLIPPAGE = "2";
export const DEFAULT_DEFI_MARKET_PRICE_PROTECTION_SLIPPAGE = "5";
export const DEFAULT_DEFI_CHILD_SLIPPAGE = "5";
export const DEFAULT_DEFI_MARKET_SLIPPAGE = "0";
export const DEFAULT_ORDER_CLIPSIZE = "100";
export const MAX_SLIPPAGE_VALUE = 50;

export const CHAIN_ID_DEFAULT_TOKEN_PAIRS: Record<ChainId, { from: Token; to: Token }> = {
  [ChainId.ETHEREUM]: {
    from: CHAIN_ID_DEFAULT_TOKENS[ChainId.ETHEREUM].find((x) => x.symbol === "WETH")!,
    to: CHAIN_ID_DEFAULT_TOKENS[ChainId.ETHEREUM].find((x) => x.symbol === "USDT")!,
  },
  [ChainId.BSC]: {
    from: CHAIN_ID_DEFAULT_TOKENS[ChainId.BSC].find((x) => x.symbol === "WBNB")!,
    to: CHAIN_ID_DEFAULT_TOKENS[ChainId.BSC].find((x) => x.symbol === "USDT")!,
  },
  [ChainId.POLYGON]: {
    from: CHAIN_ID_DEFAULT_TOKENS[ChainId.POLYGON].find((x) => x.symbol === "WMATIC")!,
    to: CHAIN_ID_DEFAULT_TOKENS[ChainId.POLYGON].find((x) => x.symbol === "USDT")!,
  },
  [ChainId.AVALANCHE]: {
    from: CHAIN_ID_DEFAULT_TOKENS[ChainId.AVALANCHE].find((x) => x.symbol === "WAVAX")!,
    to: CHAIN_ID_DEFAULT_TOKENS[ChainId.AVALANCHE].find((x) => x.symbol === "USDT.e")!,
  },
  [ChainId.ARBITRUM]: {
    from: CHAIN_ID_DEFAULT_TOKENS[ChainId.ARBITRUM].find((x) => x.symbol === "WETH")!,
    to: CHAIN_ID_DEFAULT_TOKENS[ChainId.ARBITRUM].find((x) => x.symbol === "USDT")!,
  },
  [ChainId.OPTIMISM]: {
    from: CHAIN_ID_DEFAULT_TOKENS[ChainId.OPTIMISM].find((x) => x.symbol === "WETH")!,
    to: CHAIN_ID_DEFAULT_TOKENS[ChainId.OPTIMISM].find((x) => x.symbol === "USDT")!,
  },
  [ChainId.BASE]: {
    from: CHAIN_ID_DEFAULT_TOKENS[ChainId.BASE].find((x) => x.symbol === "WETH")!,
    to: CHAIN_ID_DEFAULT_TOKENS[ChainId.BASE].find((x) => x.symbol === "USDC")!,
  },
};

export const DEFAULT_CHAIN_ID = ChainId.POLYGON;

export const CHAIN_LOGO: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: ethereumUrl,
  [ChainId.BSC]: bscUrl,
  [ChainId.POLYGON]: polygonUrl,
  [ChainId.AVALANCHE]: avalancheUrl,
  [ChainId.ARBITRUM]: arbitrumUrl,
  [ChainId.OPTIMISM]: optimismUrl,
  [ChainId.BASE]: baseUrl,
};

export const CHAIN_NAME: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: "Ethereum",
  [ChainId.BSC]: "BNB chain",
  [ChainId.POLYGON]: "Polygon",
  [ChainId.AVALANCHE]: "Avalanche",
  [ChainId.ARBITRUM]: "Arbitrum",
  [ChainId.OPTIMISM]: "Optimism",
  [ChainId.BASE]: "Base",
};

export const CHAIN_IDS: ChainId[] = [
  ChainId.ETHEREUM,
  ChainId.BSC,
  ChainId.POLYGON,
  ChainId.AVALANCHE,
  ChainId.ARBITRUM,
  ChainId.OPTIMISM,
  ChainId.BASE,
];

export const CHAIN_CONFIG: Record<ChainId, ChainConfig> = {
  // its already in metamask
  [ChainId.ETHEREUM]: {
    chainId: getChainIdHex(ChainId.ETHEREUM),
    chainName: CHAIN_NAME[ChainId.ETHEREUM],
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://rpc.flashbots.net/"],
    blockExplorerUrls: ["https://etherscan.io"],
    iconUrls: [],
  },
  // https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain
  [ChainId.BSC]: {
    chainId: getChainIdHex(ChainId.BSC),
    chainName: CHAIN_NAME[ChainId.BSC],
    nativeCurrency: { name: "BNB", symbol: "BNB", decimals: 18 },
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
    iconUrls: [],
  },
  // https://ethereum.stackexchange.com/questions/115565/metamask-wallet-addethereumchain-is-not-working-when-using-polygon-mainet-but-w
  [ChainId.POLYGON]: {
    chainId: getChainIdHex(ChainId.POLYGON),
    chainName: CHAIN_NAME[ChainId.POLYGON],
    nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
    iconUrls: [],
  },
  // https://docs.avax.network/dapps/smart-contracts/add-avalanche-to-metamask-programmatically/
  [ChainId.AVALANCHE]: {
    chainId: getChainIdHex(ChainId.AVALANCHE),
    chainName: CHAIN_NAME[ChainId.AVALANCHE],
    nativeCurrency: { name: "AVAX", symbol: "AVAX", decimals: 18 },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io"],
    iconUrls: [],
  },
  [ChainId.ARBITRUM]: {
    chainId: getChainIdHex(ChainId.ARBITRUM),
    chainName: CHAIN_NAME[ChainId.ARBITRUM],
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://arbiscan.io"],
    iconUrls: [],
  },
  [ChainId.OPTIMISM]: {
    chainId: getChainIdHex(ChainId.OPTIMISM),
    chainName: CHAIN_NAME[ChainId.OPTIMISM],
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://mainnet.optimism.io"],
    blockExplorerUrls: ["https://optimistic.etherscan.io"],
    iconUrls: [],
  },
  [ChainId.BASE]: {
    chainId: getChainIdHex(ChainId.BASE),
    chainName: CHAIN_NAME[ChainId.BASE],
    nativeCurrency: { name: "ETH", symbol: "ETH", decimals: 18 },
    rpcUrls: ["https://mainnet.base.org"],
    blockExplorerUrls: ["https://basescan.org/"],
    iconUrls: [],
  },
};

export const CHAIN_ID_ANBOTO_CONTRACT_ADDRESS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: "0x929731831D39e765ebA04411930eb5414208d18A",
  [ChainId.BSC]: "0xF1d328fEEbCB7A80e3b20Ef53997E11fB52f0E52",
  [ChainId.POLYGON]: "0xF1d328fEEbCB7A80e3b20Ef53997E11fB52f0E52",
  [ChainId.AVALANCHE]: "0xdC68d1f5C8A62682f749a8C7Bd8Ea06447a5Eb41",
  [ChainId.ARBITRUM]: "0x69883Edb709DD6a383ab80C132bE388bde824aD3",
  [ChainId.OPTIMISM]: "0xBE9a2279b0c73AEfD64dCF01717Ea0D00Bd7613f",
  [ChainId.BASE]: "0x2f5703539d5Cf1342B2E13e905A6D1b2E0C7313b",
};

export const CHAIN_ID_ANBOTO_CONTRACT_ADDRESS_V2: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: "0xEb401fab7F573Ea207c6980E4341D5bBdB0D9Eb8",
  [ChainId.BSC]: "0x8565494F229AB837b019DE5a37B95f52753e2790",
  [ChainId.POLYGON]: "0x4E4e82Ba573137e35cA866c55120AA69CAB9A060",
  [ChainId.AVALANCHE]: "0x82a7d055ba799c191ec62C03BB3483f0a6B2075A",
  [ChainId.ARBITRUM]: "0x482bc3920bcd407bA80421471087d6146D32D754",
  [ChainId.OPTIMISM]: "0xc953A8BF216327999C1a3FcdCee9176DdC9d1f22",
  [ChainId.BASE]: "0x2f5703539d5Cf1342B2E13e905A6D1b2E0C7313b",
};

export const USDT_ETH_ADDRESS = "0xdac17f958d2ee523a2206206994597c13d831ec7";

export const OPTIMISM_GAS_PRICE_ORACLE_ADDRESS = "0x420000000000000000000000000000000000000F";

export const isSupportedChainId = (chainId: number): chainId is ChainId =>
  Object.prototype.hasOwnProperty.call(CHAIN_CONFIG, chainId);

export const TOKEN_NAME_INPUT_DEBOUNCE_MS = 300;
export const AMOUNT_INPUT_DEBOUNCE_MS = 1000;
export const CLIP_SIZE_AUTOMATIC_DEBOUNCE_MS = 1000;

export const ANBOTO_CONTRACT_DOMAIN = {
  name: "AnbotoExecV1",
  version: "1",
};

export const ANBOTO_CONTRACT_DOMAIN_V2 = {
  name: "AnbotoExecV2",
  version: "2",
};

export const ANBOTO_CONTRACT_ORDER_STRUCT_TYPES = {
  Order: [
    { name: "inputToken", type: "address" },
    { name: "outputToken", type: "address" },
    { name: "totalAmount", type: "uint256" },
    { name: "outMin", type: "uint256" },
    { name: "maxGasPrice", type: "uint256" },
    { name: "feeAmount", type: "uint256" },
    { name: "deadline", type: "uint256" },
    { name: "salt", type: "uint256" },
  ],
};

export const ANBOTO_CONTRACT2_ORDER_STRUCT_TYPES = {
  Order: [
    { name: "inputToken", type: "address" },
    { name: "totalAmount", type: "uint256" },
    { name: "outputToken", type: "address" },
    { name: "outMin", type: "uint256" },
    { name: "maxGasPrice", type: "uint256" },
    { name: "maxFeeAbsolute", type: "uint256" },
    { name: "feePercent", type: "uint256" },
    { name: "isFeeTakenInInput", type: "bool" },
    { name: "deadline", type: "uint256" },
    { name: "salt", type: "uint256" },
  ],
};

export const WEI_IN_ETH = 10 ** 18;

export const QUOTE_AUTO_RELOAD_SECS = 30;

export const GAS_PRICE_OPTION_GAS_ORACLE_PROP: Record<GasPriceOptionType, string> = {
  [GasPriceOptionType.HIGH]: "high",
  [GasPriceOptionType.MEDIUM]: "medium",
  [GasPriceOptionType.SLOW]: "low",
};

export const DEFI_STRATEGIES: DefiOrderExecutionStrategy[] = [
  OrderExecutionStrategy.ORDER,
  OrderExecutionStrategy.TWAP,
  OrderExecutionStrategy.DCA,
  OrderExecutionStrategy.LIMIT,
];

export const STRATEGY_DESCRIPTION: Record<DefiOrderExecutionStrategy, string> = {
  [OrderExecutionStrategy.TWAP]:
    "Time-weighted average price strategy breaks up a large order and releases dynamically determined smaller chunks of the order to the market using evenly divided time slots between a start and end time. The aim is to execute the order close to the average price between the start and end times thereby minimizing market impact.",
  [OrderExecutionStrategy.ICEBERG]:
    'Iceberg orders are large single orders that have been divided into smaller limit orders for the purpose of hiding the actual order quantity. The term "iceberg" comes from the fact that the visible lots are just the "tip of the iceberg" given the greater number of limit orders ready to be placed.',
  [OrderExecutionStrategy.ORDER]:
    "A market order is an order to buy or sell a stock at the market's current best available price. Market orders are optimal when the primary goal is to execute the trade immediately.",
  [OrderExecutionStrategy.LIMIT]:
    "A limit order is an order to buy or sell a security at a specified price or better. The order will only be executed at the specified price or a better price. It allows traders to control the price at which they buy or sell a security.",
  [OrderExecutionStrategy.DCA]:
    "Dollar-Cost Averaging (DCA) strategy involves investing a fixed amount of money at regular intervals, regardless of the asset's price. This approach aims to reduce the impact of volatility over time by spreading the investment across different market conditions.",
  [OrderExecutionStrategy.POV]:
    "Percent of volume (POV) strategy designed to control execution pace by targeting a percentage of market volume. Emphasis on staying as close to the stated POV rate as possible.",
};

export const STRATEGY_NAME: Record<DefiOrderExecutionStrategy, string> = {
  [OrderExecutionStrategy.TWAP]: "TWAP",
  [OrderExecutionStrategy.ICEBERG]: "Iceberg",
  [OrderExecutionStrategy.ORDER]: "Swap",
  [OrderExecutionStrategy.LIMIT]: "Limit",
  [OrderExecutionStrategy.POV]: "POV",
  [OrderExecutionStrategy.DCA]: "DCA",
};

export const GAS_PRICE_OPTIONS: GasPriceOptionType[] = [
  GasPriceOptionType.HIGH,
  GasPriceOptionType.MEDIUM,
  GasPriceOptionType.SLOW,
];

export const GAS_PRICE_OPTION_NAME: Record<GasPriceOptionType, string> = {
  [GasPriceOptionType.HIGH]: "High",
  [GasPriceOptionType.MEDIUM]: "Medium",
  [GasPriceOptionType.SLOW]: "Low",
};

export const TRANSACTION_SPEED_OPTION_NAME: Record<GasPriceOptionType, string> = {
  [GasPriceOptionType.HIGH]: "Fast",
  [GasPriceOptionType.MEDIUM]: "Standard",
  [GasPriceOptionType.SLOW]: "Slow",
};

export const CHAIN_ID_GAS_PRICE_OPTION_TIME: Record<
  ChainId,
  Record<GasPriceOptionType, { from: number; to: number }>
> = {
  [ChainId.ETHEREUM]: {
    [GasPriceOptionType.HIGH]: { from: 5, to: 10 },
    [GasPriceOptionType.MEDIUM]: { from: 10, to: 30 },
    [GasPriceOptionType.SLOW]: { from: 30, to: 60 },
  },
  [ChainId.BSC]: {
    [GasPriceOptionType.HIGH]: { from: 5, to: 10 },
    [GasPriceOptionType.MEDIUM]: { from: 10, to: 30 },
    [GasPriceOptionType.SLOW]: { from: 30, to: 60 },
  },
  [ChainId.POLYGON]: {
    [GasPriceOptionType.HIGH]: { from: 5, to: 10 },
    [GasPriceOptionType.MEDIUM]: { from: 10, to: 30 },
    [GasPriceOptionType.SLOW]: { from: 30, to: 60 },
  },
  [ChainId.AVALANCHE]: {
    [GasPriceOptionType.HIGH]: { from: 5, to: 10 },
    [GasPriceOptionType.MEDIUM]: { from: 10, to: 30 },
    [GasPriceOptionType.SLOW]: { from: 30, to: 60 },
  },
  [ChainId.ARBITRUM]: {
    [GasPriceOptionType.HIGH]: { from: 5, to: 10 },
    [GasPriceOptionType.MEDIUM]: { from: 10, to: 30 },
    [GasPriceOptionType.SLOW]: { from: 30, to: 60 },
  },
  [ChainId.OPTIMISM]: {
    [GasPriceOptionType.HIGH]: { from: 5, to: 10 },
    [GasPriceOptionType.MEDIUM]: { from: 10, to: 30 },
    [GasPriceOptionType.SLOW]: { from: 30, to: 60 },
  },
  [ChainId.BASE]: {
    [GasPriceOptionType.HIGH]: { from: 5, to: 10 },
    [GasPriceOptionType.MEDIUM]: { from: 10, to: 30 },
    [GasPriceOptionType.SLOW]: { from: 30, to: 60 },
  },
};

export const ORDER_TYPE_NAME: Record<OrderType, string> = {
  [OrderType.MARKET]: "Market",
  [OrderType.LIMIT]: "Limit",
};

// took max gas used for executeOrder invocation of Anboto smart contract in tests - 251951
// lets round it for safety. unused gas is returning to user anyway
export const MIN_GAS_UNITS_PER_SLICE = 300000;
export const MIN_GAS_UNITS_PER_SLICE_ARBITRUM = 10000000;

export const PAY_TOKEN_MIN_AMOUNT_IN_USD = 0.05;

export const MAX_GAS_PRICE_MULTIPLY_RATIO = 2;

export const STOPABLE_STATUSES = ["waiting", "waiting_allowance", "running", "paused"];

export const loaderData = Array.from(Array(10)).map(() => ({}));

export const GAS_PER_SLICE: Record<ChainId, number> = {
  [ChainId.ETHEREUM]: MIN_GAS_UNITS_PER_SLICE,
  [ChainId.BSC]: MIN_GAS_UNITS_PER_SLICE,
  [ChainId.POLYGON]: MIN_GAS_UNITS_PER_SLICE,
  [ChainId.AVALANCHE]: MIN_GAS_UNITS_PER_SLICE,
  [ChainId.ARBITRUM]: MIN_GAS_UNITS_PER_SLICE_ARBITRUM,
  [ChainId.OPTIMISM]: MIN_GAS_UNITS_PER_SLICE,
  [ChainId.BASE]: MIN_GAS_UNITS_PER_SLICE,
};

export const DEFI_FORM_RESET_FROM_HISTORY = "defi-form-reset-from-history";

export enum DEFI_EXCHANGES_IDS {
  "0X" = "0x",
  "1INCH" = "1inch",
  UNISWAP = "uniswap",
  KYBERSWAP = "kyberswap",
  PARASWAP = "paraswap",
  OKX_DEX = "okx_dex",
  OPENOCEAN = "openocean",
  ODOS = "odos",
  DEFI_SOR = "defi_sor",
}

export const DEFI_SERVER_EXCHANGE_NAMES: Record<DEFI_EXCHANGES_IDS | "defi_sor", string> = {
  [DEFI_EXCHANGES_IDS["0X"]]: "0x",
  [DEFI_EXCHANGES_IDS["1INCH"]]: "1INCH",
  [DEFI_EXCHANGES_IDS.KYBERSWAP]: "Kyberswap",
  [DEFI_EXCHANGES_IDS.UNISWAP]: "Uniswap",
  [DEFI_EXCHANGES_IDS.PARASWAP]: "ParaSwap",
  [DEFI_EXCHANGES_IDS.OPENOCEAN]: "OpenOcean",
  [DEFI_EXCHANGES_IDS.OKX_DEX]: "Okx",
  [DEFI_EXCHANGES_IDS.ODOS]: "Odos",
  [DEFI_EXCHANGES_IDS.DEFI_SOR]: "DeFi",
};

export const DEFI_EXCHANGE_LOGOS_PATH: Record<Exclude<DEFI_EXCHANGES_IDS, DEFI_EXCHANGES_IDS.DEFI_SOR>, string> = {
  [DEFI_EXCHANGES_IDS["0X"]]: zeroXUrl,
  [DEFI_EXCHANGES_IDS["1INCH"]]: oneInchUrl,
  [DEFI_EXCHANGES_IDS.KYBERSWAP]: kyberswapUrl,
  [DEFI_EXCHANGES_IDS.UNISWAP]: uniswapUrl,
  [DEFI_EXCHANGES_IDS.PARASWAP]: paraswapUrl,
  [DEFI_EXCHANGES_IDS.OKX_DEX]: okx_dexUrl,
  [DEFI_EXCHANGES_IDS.OPENOCEAN]: openoceanUrl,
  [DEFI_EXCHANGES_IDS.ODOS]: odosUrl,
};

export const SWAP_SLIPPAGE_INFO = "Swap slippage: you set the slippage max for each child order (slices)";
export const PRICE_SAFEGUARD_INFO =
  "If the market price falls beyond your specified price safeguard, your order will not execute and remains protected.";

export const PRICE_PROTECTION_INFO =
  "Price protection: if the price drops below your limit, the TWAP will wait until the price gets back above the limit. The TWAP may not fully complete during the time set when price protection is activated";

export const PRICE_PROTECTION_OPTIONS = ["Market", "Price protection"];

export const EXPIRATIONS: DefiOrderExpiration[] = [
  DefiOrderExpiration.GTC,
  DefiOrderExpiration.DAY,
  DefiOrderExpiration.WEEK,
  DefiOrderExpiration.MONTH,
  DefiOrderExpiration.HALF_YEAR,
];

export const EXPIRATION_NAME: Record<DefiOrderExpiration, string> = {
  [DefiOrderExpiration.GTC]: "GTC",
  [DefiOrderExpiration.DAY]: "24h",
  [DefiOrderExpiration.WEEK]: "1 week",
  [DefiOrderExpiration.MONTH]: "1 month",
  [DefiOrderExpiration.HALF_YEAR]: "6 months",
};

export const DEFI_EXPIRATIONS: OrderPreferencesExpirations[] = [
  OrderPreferencesExpirations.GTC,
  OrderPreferencesExpirations.DAY,
  OrderPreferencesExpirations.WEEK,
  OrderPreferencesExpirations.MONTH,
  OrderPreferencesExpirations.SIX_MONTH,
];

export const DEFI_EXPIRATION_NAME: Record<OrderPreferencesExpirations, string> = {
  [OrderPreferencesExpirations.GTC]: "GTC",
  [OrderPreferencesExpirations.DAY]: "24h",
  [OrderPreferencesExpirations.WEEK]: "1 week",
  [OrderPreferencesExpirations.MONTH]: "1 month",
  [OrderPreferencesExpirations.SIX_MONTH]: "6 months",
};

export const DEFI_FALLBACK_RPCS: Record<ChainId, string> = {
  [ChainId.ETHEREUM]: "https://eth-mainnet.public.blastapi.io",
  [ChainId.BSC]: "https://bsc-dataseed.bnbchain.org",
  [ChainId.POLYGON]: "https://polygon-mainnet.public.blastapi.io",
  [ChainId.AVALANCHE]: "https://ava-mainnet.public.blastapi.io/ext/bc/C/rpc",
  [ChainId.ARBITRUM]: "https://arbitrum-one.public.blastapi.io",
  [ChainId.OPTIMISM]: "https://optimism-mainnet.public.blastapi.io",
  [ChainId.BASE]: "https://base-mainnet.public.blastapi.io",
};

export const DCA_FREQUENCY_STEPS = [
  {
    value: 1,
    label: "10min",
    seconds: 600,
    frequency: "10",
    frequencyUnit: OrderTradingDurationUnit.MINUTES,
  },
  {
    value: 15,
    label: "1h",
    seconds: 3600,
    frequency: "1",
    frequencyUnit: OrderTradingDurationUnit.HOURS,
  },
  {
    value: 29,
    label: "2h",
    seconds: 7200,
    frequency: "2",
    frequencyUnit: OrderTradingDurationUnit.HOURS,
  },
  {
    value: 43,
    label: "4h",
    seconds: 14400,
    frequency: "4",
    frequencyUnit: OrderTradingDurationUnit.HOURS,
  },
  {
    value: 57,
    label: "12h",
    seconds: 43200,
    frequency: "12",
    frequencyUnit: OrderTradingDurationUnit.HOURS,
  },
  {
    value: 71,
    label: "1d",
    seconds: 86400,
    frequency: "1",
    frequencyUnit: OrderTradingDurationUnit.DAYS,
  },
  {
    value: 85,
    label: "1w",
    seconds: 604800,
    frequency: "7",
    frequencyUnit: OrderTradingDurationUnit.DAYS,
  },
  {
    value: 99,
    label: "1m",
    seconds: 2592000,
    frequency: "30",
    frequencyUnit: OrderTradingDurationUnit.DAYS,
  },
];
