import { useState } from "react";
import { Box, Modal, Stack, styled, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import TeamIcon from "@src/assets/svg/team-icon.svg?react";
import { AnbotoButton } from "@src/components/ui/AnbotoButton/AnbotoButton";

export const JoinAccountModal = (params) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);

  const onGoToAccountPage = () => {
    navigate("/settings/account");
    setOpen(false);
  };

  return (
    <Modal open={open} disableAutoFocus={true} disableEnforceFocus={true} disableEscapeKeyDown={true}>
      <StyledBox>
        <Box style={{ display: "flex", justifyContent: "center", paddingTop: 30 }}>
          <TeamIcon />
        </Box>
        <Typography sx={{ mt: 2, textAlign: "center" }}>You have joined</Typography>
        <Typography variant="h5" sx={{ textAlign: "center", paddingTop: 3 }}>
          {params.isInvitedToAccount} Account
        </Typography>
        <Stack gap={1} width="100%" display="flex" flexDirection="row" justifyContent="center" padding={5}>
          <AnbotoButton sx={{ marginX: 10 }} variant="contained" fullWidth onClick={() => onGoToAccountPage()}>
            Go to Account page
          </AnbotoButton>
        </Stack>
      </StyledBox>
    </Modal>
  );
};

const StyledBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 505,
  height: 418,
  border: "0px solid",
  borderColor: "#232C2F",
  backgroundColor: "#232C2F",
  borderRadius: 8,
});
