import { Stack, styled, Typography, useTheme } from "@mui/material";
import { SvgIconComponent } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation, useNavigate } from "react-router-dom";
import { styledOptions } from "@src/utils/styled-options";

type StepsThumbProps = {
  title: string;
  description: string;
  icon: { icon: SvgIconComponent; css: Record<string, string | number | undefined> };
  active?: boolean;
  link?: string;
};

const styledIcon = ({ icon, css }) => {
  const Icon = styled(icon)(({ theme }) => ({
    ...css,
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  return <Icon />;
};

export const StepsThumb = ({ title, description, icon, active, link }: StepsThumbProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Root
      direction="row"
      alignItems="center"
      gap={2}
      active={active}
      sx={{ pr: 0 }}
      clickable={!!link}
      onClick={() => link && navigate(link)}
      activeLink={location.pathname === link}
    >
      {styledIcon(icon)}
      <Stack sx={{ flex: 1 }}>
        <Typography variant="body2">{title}</Typography>
        <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
          {description}
        </Typography>
      </Stack>
      <Stack sx={{ minWidth: 50 }} alignItems="center" justifyContent="center">
        {active && <CheckIcon sx={{ color: theme.custom.colors.secondary }} />}
      </Stack>
    </Root>
  );
};

const Root = styled(
  Stack,
  styledOptions
)<{ active?: boolean; clickable?: boolean; activeLink: boolean }>(({ theme, clickable, activeLink }) => ({
  background: theme.custom.background.secondary,
  borderRadius: theme.shape.borderRadius,
  padding: 8,
  border: `1px solid`,
  borderColor: activeLink ? theme.custom.colors.secondary : theme.custom.background.secondary,
  cursor: clickable ? "pointer" : "default",
}));
