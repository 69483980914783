import { WidgetName, WidgetConfig } from "../../types";

export const OtcOrderFormWidgetConfig = {
  name: WidgetName.OtcOrderForm,
  layout: {
    xxl: { x: 0, y: 0, w: 3, h: 14, minW: 2, minH: 15 },
    xl: { x: 0, y: 0, w: 3, h: 14, minW: 3, minH: 15 },
    lg: { x: 0, y: 0, w: 4, h: 14, minW: 4, minH: 15 },
    md: { x: 0, y: 0, w: 5, h: 14, minW: 4, minH: 15 },
    sm: { x: 0, y: 0, w: 6, h: 14, minW: 6, minH: 15 },
    xs: { x: 0, y: 0, w: 6, h: 14, minW: 6, minH: 15 },
  },
  options: {
    withGroups: true,
    maxNumber: 5,
    multiple: true,
    removable: true,
    symbolSelect: true,
  },
} as WidgetConfig;
