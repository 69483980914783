import React, { useEffect } from "react";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import CefiIcon from "./icons/cefi.svg?react";
import CefiIconActive from "./icons/cefi-active.svg?react";
import DefiIcon from "./icons/defi.svg?react";
import DefiIconActive from "./icons/defi-active.svg?react";
import AnalyticsIcon from "./icons/analytics.svg?react";
import PortfolioIcon from "./icons/portfolio.svg?react";
import AnalyticsIconActive from "./icons/analytics-active.svg?react";
import PortfolioIconActive from "./icons/portfolio-active.svg?react";
import AffiliateIcon from "./icons/affiliate.svg?react";
import AffiliateIconActive from "./icons/affiliate-active.svg?react";
import DocsIcon from "./icons/docs.svg?react";
import SupportIcon from "./icons/support.svg?react";
import ApiIcon from "./icons/api.svg?react";
import ApiIconActive from "./icons/api-active.svg?react";
import OtcIcon from "./icons/otc.svg?react";
import OtcIconActive from "./icons/otc-active.svg?react";
import { MenuItemId } from "./main-menu-dropdown/constants";
import { MenuPart } from "@src/components";
import { Sidebar } from "@src/components/sidebar/sidebar";
import { SupportButton } from "@src/components/support-button";
import { useAppSelector } from "@src/store/hooks";
import { DOCS_LINK } from "@src/constants/common";
import { TESTNET_WHITELIST, useTestnetEnabled } from "@src/hooks/use-testnet-enabled";
import { usePermissions } from "@src/hooks/use-permissions";

const MENU: MenuPart[] = [
  {
    header: "",
    offset: 1.5,
    items: [
      {
        id: MenuItemId.Cex,
        url: "/trades/cefi",
        label: "Centralized Exchange",
        icon: <CefiIcon height={22} width={22} />,
        iconActive: <CefiIconActive height={22} width={22} />,
      },
      {
        id: MenuItemId.Dex,
        url: "/trades/defi",
        label: "Decentralized Exchange",
        icon: <DefiIcon height={22} width={22} />,
        iconActive: <DefiIconActive height={22} width={22} />,
      },
      {
        id: MenuItemId.Otc,
        url: "/trades/otc",
        label: "OTC",
        icon: <OtcIcon height={22} width={22} />,
        iconActive: <OtcIconActive height={22} width={22} />,
      },
      {
        id: MenuItemId.Portfolio,
        url: "/portfolio",
        label: "Portfolio",
        icon: <PortfolioIcon height={18} width={18} />,
        iconActive: <PortfolioIconActive height={18} width={18} />,
      },
      {
        id: MenuItemId.Analytics,
        url: "/analytics",
        label: "Analytics",
        icon: <AnalyticsIcon height={20} width={20} />,
        iconActive: <AnalyticsIconActive height={20} width={20} />,
      },
    ],
  },
];

const BOTTOM_MENU: MenuPart[] = [
  {
    header: "",
    offset: 1.5,
    items: [
      {
        id: MenuItemId.FeesDashboard,
        url: "/fees-dashboard",
        label: "Fees Dashboard",
        icon: <MonetizationOnOutlinedIcon sx={{ fontSize: 18 }} />,
        iconActive: <MonetizationOnOutlinedIcon sx={{ fontSize: 18 }} />,
      },
      {
        id: MenuItemId.Api,
        url: "/settings/api",
        label: "API",
        icon: <ApiIcon height={20} width={20} />,
        iconActive: <ApiIconActive height={20} width={20} />,
      },
      {
        id: MenuItemId.Affiliate,
        url: "/affiliate-program",
        label: "Affiliate program",
        icon: <AffiliateIcon height={20} width={20} />,
        iconActive: <AffiliateIconActive height={20} width={20} />,
      },
      {
        id: MenuItemId.Documentation,
        url: DOCS_LINK,
        external: true,
        label: "Documentation",
        icon: <DocsIcon height={20} width={20} />,
        iconActive: <DocsIcon height={20} width={20} />,
      },
      {
        id: MenuItemId.Support,
        url: "",
        label: "Support",
        component: SupportButton,
        icon: <SupportIcon height={18} width={18} />,
        iconActive: <SupportIcon height={18} width={18} />,
        className: "support-button",
      },
    ],
  },
];

export const MainSidebar = () => {
  const [menu, setMenu] = React.useState<any>();
  const [bottomMenu, setBottomMenu] = React.useState<any>();
  const isAnalyticsVisible = useAppSelector((state) => state.user.is_analytics_visible);
  const isFeesDashboardVisible = useAppSelector((state) => state.user.is_fees_dashboard_visible);
  const isOtcVisible = useAppSelector((state) => state.user.is_otc_visible);
  const isTestnet = useTestnetEnabled();
  const permissions = usePermissions();

  useEffect(() => {
    if (!isAnalyticsVisible) MENU[0].items = MENU[0].items.filter((item) => item.id !== MenuItemId.Analytics);
    if (isTestnet) {
      MENU[0].items = MENU[0].items.filter((item) => TESTNET_WHITELIST.includes(item.id));
      BOTTOM_MENU[0].items = BOTTOM_MENU[0].items.filter((item) => TESTNET_WHITELIST.includes(item.id));
    }
    if (!isFeesDashboardVisible)
      BOTTOM_MENU[0].items = BOTTOM_MENU[0].items.filter((item) => item.id !== MenuItemId.FeesDashboard);

    if (!permissions.trade) {
      BOTTOM_MENU[0].items = BOTTOM_MENU[0].items.filter((item) => ![MenuItemId.Affiliate].includes(item.id));
    }

    if (!isOtcVisible) {
      MENU[0].items = MENU[0].items.filter((item) => item.id !== MenuItemId.Otc);
    }

    setMenu(MENU);
    setBottomMenu(BOTTOM_MENU);
  }, []);

  return menu && <Sidebar disableExpand menu={menu} bottomMenu={bottomMenu} />;
};
