import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { MetaMaskInpageProvider } from "@metamask/providers";
import { TransactionResponse } from "@ethersproject/providers";
import { ethers } from "ethers";
// import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
// import { connectorsForWallets, Chain } from "@rainbow-me/rainbowkit";
import { Chain } from "@rainbow-me/rainbowkit";
// import {
//   injectedWallet,
//   metaMaskWallet,
//   rabbyWallet,
//   rainbowWallet,
//   walletConnectWallet,
//   ledgerWallet,
//   coinbaseWallet,
//   phantomWallet,
// } from "@rainbow-me/rainbowkit/wallets";
// import { createConfig } from "wagmi";
// import { arbitrum, avalanche, bsc, mainnet, polygon, optimism } from "wagmi/chains";
import ERC20ABI from "@src/assets/ABIs/ERC20.json";
import { WALLET_CONNECT_PROVIDER_OPTIONS } from "@src/store/constants";
import {
  ANBOTO_CONTRACT_DOMAIN,
  ANBOTO_CONTRACT_ORDER_STRUCT_TYPES,
  CHAIN_CONFIG,
  CHAIN_ID_ANBOTO_CONTRACT_ADDRESS,
  DEFAULT_CHAIN_ID,
  // DEFI_FALLBACK_RPCS,
} from "@src/pages/defi/constants";
import { ApproveTokenFeeMutationParams, ApproveTokenMutationParams } from "@src/store/apis/blockchainApi/types";
import { SignOrderQueryParams } from "@src/store/apis/anbotoApi/types";
import { ChainId } from "@src/pages/defi/types";
import { getChainIdHex } from "@src/pages/defi/hex-utils";

type WalletProvider = null | "METAMASK" | "WC";

// TODO - replace any with types
type BlockchainInitialState = {
  chains: any;
  wagmiConfig: any | null;
  walletType: WalletProvider;
  walletProvider: null | MetaMaskInpageProvider | WalletConnectProvider;
  account: null | string;
  chainId: number;
};

export const base: Chain = {
  id: 8453,
  // network: "base",
  iconUrl: "https://raw.githubusercontent.com/base-org/brand-kit/main/logo/symbol/Base_Symbol_Blue.svg",
  iconBackground: "#fff",
  name: "Base",
  nativeCurrency: { name: "Base", symbol: "ETH", decimals: 18 },
  rpcUrls: {
    default: {
      http: ["https://mainnet.base.org"],
    },
    public: {
      http: ["https://mainnet.base.org"],
    },
  },
  blockExplorers: {
    blockscout: {
      name: "Basescout",
      url: "https://base.blockscout.com",
    },
    default: {
      name: "Basescan",
      url: "https://basescan.org",
    },
    etherscan: {
      name: "Basescan",
      url: "https://basescan.org",
    },
  },
  contracts: {
    multicall3: {
      address: "0xca11bde05977b3631167028862be2a173976ca11",
      blockCreated: 5022,
    },
  },
};

const initialState: BlockchainInitialState = {
  chains: [],
  wagmiConfig: null,
  walletType: (localStorage.getItem("walletType") as "METAMASK" | "WC") || null,
  walletProvider: null,
  account: localStorage.getItem("account") || null,
  chainId: (Number(localStorage.getItem("chainId")) as ChainId) || DEFAULT_CHAIN_ID,
};

export const onWalletDisconnect = createAction<{ code: number; reason: string }>("blockchain/onWalletDisconnect");
export const onWalletChainChanged = createAction<number>("blockchain/onWalletChainChanged");
export const onWalletAccountsChanged = createAction<string[]>("blockchain/onWalletAccountsChanged");

export const connectMetamaskWallet = createAsyncThunk(
  "blockchain/connectMetamaskWallet",
  async (args, { dispatch }) => {
    const provider = window.ethereum;
    const accounts = await provider.enable();

    provider.on("chainChanged", (chainIdHex: string) => dispatch(onWalletChainChanged(parseInt(chainIdHex, 16))));
    provider.on("accountsChanged", (accounts: string[]) =>
      accounts.length
        ? dispatch(onWalletAccountsChanged(accounts))
        : dispatch(
            onWalletDisconnect({
              code: 1,
              reason: "Metamask has been disconnected from this site by user",
            })
          )
    );
    provider.on("disconnect", ({ code, message }: { code: number; message: string }) =>
      dispatch(
        onWalletDisconnect({
          code,
          reason: message,
        })
      )
    );
    provider.on("message", (x) => console.log("metamask message", x));

    const chainId = parseInt(provider.chainId!);

    return { provider, account: accounts![0], chainId };
  }
);

export const connectWcWallet = createAsyncThunk(
  "blockchain/connectWcWallet",
  async (formChainId: number, { dispatch }) => {
    const provider = new WalletConnectProvider({ ...WALLET_CONNECT_PROVIDER_OPTIONS, chainId: formChainId });
    const accounts = await provider.enable();

    provider.on("chainChanged", (chainIdHex: string) => dispatch(onWalletChainChanged(parseInt(chainIdHex, 16))));
    provider.on("accountsChanged", (accounts) => dispatch(onWalletAccountsChanged(accounts as string[])));
    provider.on("disconnect", (code: number, reason: string) => dispatch(onWalletDisconnect({ code, reason })));
    provider.on("message", (x) => console.log("wc message", x));

    const chainId = provider.chainId;

    return { provider, account: accounts![0], chainId };
  }
);

export const disconnectWallet = createAsyncThunk("blockchain/disconnectWallet", async (args, { getState }) => {
  const state = getState() as any;
  const walletProvider = state.blockchain.walletProvider!;
  walletProvider.disconnect && (await walletProvider.disconnect());
  walletProvider.removeAllListeners && walletProvider.removeAllListeners("disconnect");
  walletProvider.removeAllListeners && walletProvider.removeAllListeners("accountsChanged");
  walletProvider.removeAllListeners && walletProvider.removeAllListeners("disconnect");
  walletProvider.removeAllListeners && walletProvider.removeAllListeners("message");
  walletProvider.close && walletProvider.close();
});

export const METAMASK_ERROR_CODES = {
  REJECTED: 4001,
  CHAIN_NOT_ADDED: 4902,
};

export const connectMetamaskWalletToFormChainId = createAsyncThunk(
  "blockchain/connectMetamaskWalletToFormChainId",
  async (formChainId: number, { getState }) => {
    const state = getState() as any;
    const provider = state.blockchain.walletProvider!;

    await provider
      .send("wallet_switchEthereumChain", [{ chainId: getChainIdHex(formChainId) }])
      .catch((e) =>
        e.code === METAMASK_ERROR_CODES.CHAIN_NOT_ADDED
          ? provider.send("wallet_addEthereumChain", [CHAIN_CONFIG[formChainId]])
          : Promise.reject(e)
      );
    return formChainId;
  }
);

export const approveToken = createAsyncThunk<TransactionResponse, ApproveTokenMutationParams>(
  "blockchain/approveToken",
  ({ tokenAddress, rawAmount, chainId }, { getState }) => {
    const state = getState() as any;
    const walletProvider = state.blockchain.walletProvider!;
    const provider = new ethers.providers.Web3Provider(walletProvider, "any");
    const signer = provider.getSigner();
    const anbotoContractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS[chainId];
    const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, signer);

    return tokenContract.approve(anbotoContractAddress, rawAmount).then((x) => JSON.parse(JSON.stringify(x)));
  }
);

export const approveTokenFee = createAsyncThunk<TransactionResponse, ApproveTokenFeeMutationParams>(
  "blockchain/approveTokenFee",
  ({ tokenAddress, rawAmount, feeManagerAddress }, { getState }) => {
    const state = getState() as any;
    const walletProvider = state.blockchain.walletProvider!;
    const provider = new ethers.providers.Web3Provider(walletProvider, "any");
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, signer);
    return tokenContract.approve(feeManagerAddress, rawAmount).then((x) => JSON.parse(JSON.stringify(x)));
  }
);

export const signOrder = createAsyncThunk<string, SignOrderQueryParams>(
  "blockchain/signOrder",
  async ({ anbotoContractOrder, chainId }, { getState }) => {
    const state = getState() as any;
    const walletProvider = state.blockchain.walletProvider!;
    const provider = new ethers.providers.Web3Provider(walletProvider, "any");
    const signer = provider.getSigner();
    const anbotoContractAddress = CHAIN_ID_ANBOTO_CONTRACT_ADDRESS[chainId];
    const domain = { ...ANBOTO_CONTRACT_DOMAIN, chainId, verifyingContract: anbotoContractAddress };

    const signature = await signer._signTypedData(domain, ANBOTO_CONTRACT_ORDER_STRUCT_TYPES, anbotoContractOrder);

    const validSignature = ethers.utils.splitSignature(signature);

    return ethers.utils.joinSignature(validSignature);
  }
);

const saveAccountInfo = (account: string, walletProvider: WalletProvider) => {
  localStorage.setItem("account", account);
  localStorage.setItem("walletType", walletProvider as string);
};

export const blockchainSlice = createSlice({
  name: "blockchain",
  initialState,
  reducers: {
    // initializeRainbowKit: (state) => {
    //   const { chains, publicClient, webSocketPublicClient } = configureChains(
    //     //[mainnet],
    //     [bsc, mainnet, polygon, optimism, arbitrum, avalanche, base],
    //     [
    //       jsonRpcProvider({
    //         rpc: (chain) => {
    //           return { http: DEFI_FALLBACK_RPCS[chain.id] };
    //         },
    //       }),
    //       publicProvider(),
    //     ]
    //   );
    //   const projectId = import.meta.env.VITE_APP_WALLET_CONNECT_PROJECT_ID!;
    //   const appName = "Anboto App";
    //   const connectors = connectorsForWallets([
    //     {
    //       groupName: "Anboto wallets",
    //       wallets: [
    //         injectedWallet({ chains }),
    //         rainbowWallet({ projectId, chains }),
    //         metaMaskWallet({ projectId, chains }),
    //         rabbyWallet({ chains }),
    //         walletConnectWallet({ projectId, chains }),
    //         ledgerWallet({ projectId, chains }),
    //         coinbaseWallet({ appName, chains }),
    //         phantomWallet({ chains }),
    //       ],
    //     },
    //   ]);
    //   const wagmiConfig = createConfig({
    //     autoConnect: true,
    //     connectors,
    //     publicClient,
    //     webSocketPublicClient,
    //   });
    //   state.wagmiConfig = wagmiConfig;
    //   state.chains = chains;
    // },
  },
  extraReducers: (builder) => {
    builder.addCase(onWalletAccountsChanged, (state, action) => {
      const selectedAccount = localStorage.getItem("account") as string;
      const accounts = action.payload;
      const targetAccount = accounts.includes(selectedAccount) ? selectedAccount : accounts[0];

      return { ...state, account: targetAccount };
    });
    builder.addCase(onWalletChainChanged, (state, action) => ({ ...state, chainId: action.payload }));
    builder.addCase(connectMetamaskWallet.fulfilled, (state, action) => {
      saveAccountInfo(action.payload.account, "METAMASK");
      localStorage.setItem("chainId", action.payload.chainId.toString());

      return {
        ...state,
        account: action.payload.account,
        walletType: "METAMASK",
        walletProvider: action.payload.provider,
        chainId: action.payload.chainId,
      };
    });
    builder.addCase(connectWcWallet.fulfilled, (state, action) => {
      saveAccountInfo(action.payload.account, "WC");
      localStorage.setItem("chainId", action.payload.chainId.toString());

      return {
        ...state,
        account: action.payload.account,
        walletType: "WC",
        walletProvider: action.payload.provider,
        chainId: action.payload.chainId,
      };
    });
    builder.addCase(disconnectWallet.fulfilled, (state) => {
      localStorage.removeItem("account");
      localStorage.removeItem("walletType");
      localStorage.removeItem("chainId");

      return {
        ...state,
        account: null,
        walletType: null,
        walletProvider: null,
      };
    });
    builder.addCase(connectMetamaskWalletToFormChainId.fulfilled, (state, action) => {
      localStorage.setItem("chainId", action.payload.toString());

      return {
        ...state,
        chainId: action.payload,
      };
    });
  },
});
